import * as actionTypes from '../contains/actionTypes';
const initialState: any = {
    award: null,
    message: ""
}

const reducer = (state = initialState , action:any) => {
    // console.log(action);
    switch (action.type) {
        case actionTypes.CHECK_AWARD_INI:
            return {
                ...state
            };
        case actionTypes.CHECK_AWARD_SUCCESS:            
            return {
                ...state,
                award: action.award
            }          
        case actionTypes.CHECK_AWARD_FAIL:
            return {
                ...state,
                message: action.message
            }
        default:
            return state;
    }
}

export default reducer;