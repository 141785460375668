import * as types from '../contains/actionTypes';
//import {enCryptData,enCryptDataClient,GetProfileString} from '../../authentication/crypto';
import {
    UPDATE_MANUAL_CODE,
    LIST_MANUAL_AWARD
} from '../contains/api';
import * as Status from '../../common/enum';
import * as config from '../../common/config';

const axios = require("axios");

export const changeLoading = (loading: boolean) => {
    return {
        type: types.CHANGE_LOADING,
        loading
    };
}

export const ManualCodeInit = () => {
    return {
        type: types.MANUAL_CODE_INI
    };
};

export const ManualCodeSuccess = (listManualCode: any) => {
    return {
        type: types.MANUAL_CODE_SUCCESS,
        listManualCode
    }
}

export const ManualCodePaging = (first: number, pageNo: number, pageRows: number, totalRecords: number) => {
    return {
        type: types.MANUAL_CODE_PAGING,
        first,
        pageNo,
        pageRows,
        totalRecords
    }
}

export const showError = (severity: any, summary: any, detail: any) => {
    return {
        type: types.ERROR_SUCCESS,
        severity,
        summary,
        detail
    }
}

export function UpdateManualCode(element, objUpdate: any) {
    return async (dispatch: any) => {
        dispatch(changeLoading(true));
        dispatch(showError(null, null, null))

        element.setAttribute("disabled", true);

        axios.post(UPDATE_MANUAL_CODE, objUpdate)
            .then((response: any) => {
                if (response.status === Status.STATUS_RESPONSE_OK) {
                    const data: any = response.data;
                    //console.log(data);
                    const {
                        message
                    } = data;
                    alert(message);
                    element.removeAttribute("disabled");
                    dispatch(changeLoading(false));
                }
            })
            .catch((error: any) => {
                dispatch(showError("error", "Lỗi", error.toString()));
                dispatch(changeLoading(false));
            })
    }
}

export function ListManualCode(awardId) {

    return async (dispatch: any) => {
        dispatch(changeLoading(true));
        dispatch(showError(null, null, null))
        axios.get(LIST_MANUAL_AWARD, {
            params: {
                awardId: awardId,
            }
        })
            .then((response: any) => {
                // console.log(response);
                if (response.status === Status.STATUS_RESPONSE_OK) {
                    const data: any = response.data;
                    //console.log(data);
                    let {
                        listCode
                    } = data;
                   
                    dispatch(ManualCodeSuccess(listCode));
                    dispatch(changeLoading(false));
                }
                dispatch(changeLoading(false));
            })
            .catch((error: any) => {
                dispatch(showError("error", "Lỗi", error.toString()));
                dispatch(changeLoading(false));
            })
    }
}
