import * as actionTypes from '../contains/actionTypes';
import {
    deCryptToken
} from '../../authentication/crypto';
import * as config from '../../common/config';

const initialState:any = {
    token: deCryptToken(),
    isLoading: false,
    message: null,
    profile: null,
    roleId: localStorage.getItem(config.CACHE_ROLEID)
}

const reducer = (state = initialState , action:any) => {
    // console.log(action);
    switch (action.type) {
        case actionTypes.AUTH_INI:
            return {
                token: deCryptToken(),
                profile:null,
                isLoading: false,
                message: null 
            };
        case actionTypes.AUTH_SUCCESS:
            return {
                token: action.token,
                isLoading: false,
                message: null ,
                profile: action.profile,
                roleId: action.roleId
            }
        case actionTypes.LOADING:
            return {
                ...state,
                isLoading: action.loading
            }
        case actionTypes.AUTH_FAIL:
            return {
                token: null,
                profile:null,
                isLoading: false,
                message: action.message
            }
        default:
            return state;
    }
}

export default reducer;