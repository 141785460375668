import * as actionTypes from '../contains/actionTypes';
const initialState: any = {
    status: false
}

const reducer = (state = initialState , action:any) => {
    // console.log(action);
    switch (action.type) {
        case actionTypes.CHANGE_LOADING:
            return {
                status: action.loading
            }
        default:
            return state;
    }
}

export default reducer;