import React, { useEffect, useState, useMemo, useRef } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../reducer/actions/index';
import PerfectScrollbar from 'react-perfect-scrollbar'
import { chunkArray } from '../../common/function';
import Header from '../../components/HeaderDisplay';
import {
    LIST_BOX,
    LIST_AWARD1,
    LIST_CUSTOMER,
    GET_LIST_NUMBER,
    INFO_USER
} from '../../reducer/contains/api';
import * as router from '../../common/router';
import * as Status from '../../common/enum';
import * as config from '../../common/config';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
const axios = require("axios");
const _ = require("lodash");
let timerCheckAward: any = null;

const Pages = (props: any) => {
    const [shiftId, setShiftId] = useState(0);
    const [listCustomer, setListCustomer] = useState([]);
    const [imageUrl, setImageUrl] = useState("");
    const [name, setName] = useState("");
    //const [description, setdescription] = useState<string>("");
    //const [name, setname] = useState<string>("");
    const [amount, setAmount] = useState<number>(0);
    const [total, setTotal] = useState<number>(0);
    const [remain, setRemain] = useState<number>(0);

    const [awardId, setawardId] = useState<number>(0);
    const [number1, setNumber1] = useState<any>(null);
    const [number2, setNumber2] = useState<any>(null);
    const [number3, setNumber3] = useState<any>(null);
    const [number4, setNumber4] = useState<any>(null);

    const [loading, setLoading] = useState(false);

    const GetListBox = () => {
        axios.get(LIST_BOX)
            .then((response: any) => {
                if (response.status === Status.STATUS_RESPONSE_OK && response.data.status === Status.STATUS_RESPONSE_OK) {
                    //setListBox(response.data.result);
                    let listBox = response.data.result;
                    let box = listBox.find(x => x.isHome === true);
                    //console.log();
                    if (box) {
                        //console.log(box);
                        //setBoxId(listBox[idx].boxId);
                        setShiftId(box.shiftId);
                        GetListGift(box.boxId);
                        GetListNumber();
                    }
                }
            })
            .catch((error: any) => {
                console.log(error);
            })
    }

    const GetListGift = (boxId) => {
        axios.get(`${LIST_AWARD1}?boxId=${boxId}`)
            .then((response: any) => {
                if (response.status === Status.STATUS_RESPONSE_OK && response.data.status === Status.STATUS_RESPONSE_OK) {
                    /* setListAward(response.data.result);*/
                    let listAward = response.data.result;
                    //console.log(listAward);
                    let idx = listAward.findIndex(x => x.isHome === true && x.boxId === boxId);
                    //console.log(listAward);
                    if (idx > -1) {
                        /*console.log(listAward[idx]);*/
                        /*setAwardId(listAward[idx].awardId)*/
                        setImageUrl(listAward[idx].urlBg);
                        setName(listAward[idx].name);
                        setawardId(listAward[idx].awardId);
                        GetListCustomer(listAward[idx].awardId);
                    }
                }
            })
            .catch((error: any) => {
                console.log(error);
            })
    }

    const GetListCustomer = (awardId) => {
        setLoading(true);
        axios.get(`${LIST_CUSTOMER}?awardId=${awardId}`)
            .then((response: any) => {
                if (response.status === Status.STATUS_RESPONSE_OK && response.data.status === Status.STATUS_RESPONSE_OK) {
                    setListCustomer(response.data.result);
                    //console.log(response.data)
                    const {
                        amount,
                        total,
                        remain
                    } = response.data.detail;
                    setLoading(false);
                    setAmount(amount);
                    setTotal(total);
                    setRemain(remain);
                }
            })
            .catch((error: any) => {
                console.log(error);
            })
    }

    const GetListNumber = () => {
        setLoading(true);
        axios.get(GET_LIST_NUMBER)
            .then((response: any) => {
                if (response.status === Status.STATUS_RESPONSE_OK && response.data.status === Status.STATUS_RESPONSE_OK) {
                    console.log(response.data)
                    if (response.data.result) {
                        const {
                            number1,
                            number2,
                            number3,
                            number4
                        } = response.data.result;
                        setNumber4(number4);
                        setNumber3(number3);
                        setNumber2(number2);
                        setNumber1(number1);                        
                        
                        //console.log(numberSelected);
                       
                    }
                }
            })
            .catch((error: any) => {
                console.log(error);
            })
    }

    //const GetInfoCustomer = (code, awardId, boxId) => {
    //    setLoading(true);
    //    axios.post(INFO_USER, { code, awardId, boxId })
    //        .then((response: any) => {
    //            if (response.status === Status.STATUS_RESPONSE_OK && response.data.status === Status.STATUS_RESPONSE_OK) {

    //                console.log(response.data)
    //                const {
    //                    amount,
    //                    total,
    //                    remain
    //                } = response.data.detail;
    //                setLoading(false);
    //                setAmount(amount);
    //                setTotal(total);
    //                setRemain(remain);
    //            }
    //        })
    //        .catch((error: any) => {
    //            console.log(error);
    //        })
    //}

    useEffect(() => {
        GetListBox();
        timerCheckAward = setInterval(() => {
            GetListBox();
        }, config.TIME_REFRESH);

        return (() => {
            clearInterval(timerCheckAward);
        })
    }, []);

    const renderLogo = () => {
        const wHeight = window.innerHeight;
        const wWidth = window.innerWidth;
        let urlText: string = "";

        switch (awardId) {
            case 2:
            case 7:
                urlText = "https://sms.tcgh.com.vn/17122021/mI5yS2GQc60XcqmueWuqI0e1rFKs8nAWZxgT2zitsFAxGqsb6A.png";
                break;
            case 3:
            case 8:
                urlText = "https://sms.tcgh.com.vn/17122021/GX8KJUldD394GY265tEwKF9EPE2H5FJHGDgvgUhucCxr307dcP.png";
                break;
            case 4:
            case 9:
                urlText = "https://sms.tcgh.com.vn/17122021/4MiNOIA2oDfwzj1m5uC1GijWvmcjpRy1dlEzI0iLE3zOzegiUz.png";
                break;
            case 5:
            case 10:
                urlText = "https://sms.tcgh.com.vn/17122021/wlUz5VCUVaXhMCfQoFANNS6sPYGJi9aetjhu9dIF1NATRkt5bU.png";
                break;
            default:
                urlText = "";
                break;

        }

        return (
            <div style={{ height: wHeight / 3, width: `100%` }}>
                <div style={{ position: "absolute", top: wHeight / 11, left: 0, height: (wHeight / 3 - wHeight / 11), display: `flex`, alignItems: `center` }}>
                    <img src={urlText} style={{ width: wWidth / 2, objectFit: 'contain' }} />
                </div>
            </div>
        )
    }

    const renderProduct = () => {
        const wHeight = window.innerHeight;
        const wWidth = window.innerWidth;

        return (
            <div style={{ position: "absolute", top: wHeight / 12, right: 15 }}>
                <img src={imageUrl} style={{ width: wWidth / 6, objectFit: 'contain', maxHeight: '25vh' }} />
            </div>
        )
    }

    const renderNo = (row, cell) => {
        return 1 + cell.rowIndex;
    }

    const renderTable = () => {
        const wHeight = window.innerHeight;
        //const wWidth = window.innerWidth;
        //console.log(listCustomer);
        return (
            <div style={{ width: `100%`, height: wHeight - (wHeight / 3), backgroundColor: `#71994b` }}>
                <div className="d-flex" style={{ borderTop: `1.5px solid #ffcc9c`, backgroundColor: `#ffca61` }}>
                    <div className="p-col-fixed d-flex justify-content-center align-items-center" style={{ width: 350, height: 40 }}>
                        <span style={{ color: '#fff', fontWeight: 600, fontSize: 24 }}>Tổng số giải: {amount}</span>
                    </div>
                    <div className="p-col-fixed d-flex justify-content-center align-items-center" style={{ width: 350, height: 40 }}>
                        <span style={{ color: '#fff', fontWeight: 600, fontSize: 24 }}>Tổng giải trúng thưởng: {total}</span>
                    </div>
                    <div className="p-col-fixed d-flex justify-content-center align-items-center" style={{ width: 350, height: 40 }}>
                        <span style={{ color: '#fff', fontWeight: 600, fontSize: 24 }}>Số giải còn lại: {remain}</span>
                    </div>
                </div>
                <div className="d-flex">
                    <div className="container-fluid" style={{ maxWidth: `70%` }}>
                        <DataTable id="tblCustomer" value={listCustomer} scrollable scrollHeight="60vh" emptyMessage="Chưa có khách hàng trúng giải">
                            <Column field="stt" header="STT" style={{ width: 70, textAlign: "right" }} headerStyle={{ textAlign: "center", width: 70, }} body={renderNo}></Column>
                            <Column field="code" header="MÃ SỐ" style={{ width: 200, textAlign: "center", }} headerStyle={{ textAlign: "center", width: 200 }}></Column>
                            <Column field="storeName" header="TÊN KHÁCH HÀNG" headerStyle={{ textAlign: "center", width: 350 }}></Column>
                            <Column field="province" header="TỈNH THÀNH" style={{ width: 200 }} headerStyle={{ textAlign: "center", width: 200 }}></Column>
                            <Column field="address" header="ĐỊA CHỈ" headerStyle={{ textAlign: "center" }}></Column>
                        </DataTable>
                    </div>
                </div>
            </div>
        )
    }

    const renderBody = () => {
        let styles: any = null;
        const windowHeight = window.innerHeight;
        const windowWidth = window.innerWidth;
        let dataCustomer = [...listCustomer];
        switch (awardId) {
            case 1:
                styles = {
                    "background-image": `url(https://file.tengroup.com.vn/wwwroot/18042022/BeLtykAD1o3efNQom87zsKLClNtmhjpS4HgcIgum2ZvQnLJlRH6P6p4fXZWUMscD59sJUWgn0356n7To.jpg)`,
                    "background-size": "100% 100%",
                    "width": "100%",
                    "height": "100%",
                    "overflow": "hidden",
                    "position": "relative",
                    "display": "flex"
                }

                

                if (number1 !==null && number2 !==null && number3!==null) {
                    let code = [number1, number2, number3].join("");
                    dataCustomer = _.filter(dataCustomer, (x) => { return x.code === `AA-${code}` })
                } else {
                    dataCustomer = [];
                }

                let html: any = [];
                //console.log(dataCustomer);
                if (dataCustomer.length === 0) {
                    html = (
                        <tr>
                            <td colSpan={4} style={{ textAlign: 'center' }}>Chưa có khách hàng trúng giải</td>
                        </tr>
                    )
                } else {
                    dataCustomer.forEach((item: any, index: number) => {
                        html.push(
                            <tr key={index}>
                                <td style={{ textAlign: 'center' }}>{item.code}</td>
                                <td>{item.storeName}</td>
                                <td style={{ textAlign: 'center' }}>{item.province}</td>
                                <td>{item.address}</td>
                            </tr>
                        )
                    })
                }


                return (
                    <div style={{ ...styles }}>
                        <div style={{ width: `40%`, height: `100%` }}></div>
                        <div style={{ width: `60%`, height: `100%` }}>
                            <div className="container">
                                <div className="row" style={{ paddingTop: `30%` }}>
                                    <div className="col">
                                        <div className="d-flex justify-content-center" style={{ paddingTop: `8.75%` }}>
                                            <div className='btn-number' style={{ height: windowHeight * 0.2125, width: windowWidth * 0.1 }}>
                                                <img src="https://file.tengroup.com.vn/wwwroot/18042022/8Eer8JnvrHab55bIpqkMbUSEgqdV6wUFtZtRACgVVAEF7kFJDanljggRtChLSRwAerURYV8PrI0WcLBd.png" style={{ height: `100%`, width: `100%`, objectFit: `contain` }} alt="" />
                                                <span>{number1}</span>
                                            </div>
                                            <div className='btn-number' style={{ height: windowHeight * 0.2125, width: windowWidth * 0.1 }}>
                                                <img src="https://file.tengroup.com.vn/wwwroot/18042022/8Eer8JnvrHab55bIpqkMbUSEgqdV6wUFtZtRACgVVAEF7kFJDanljggRtChLSRwAerURYV8PrI0WcLBd.png" style={{ height: `100%`, width: `100%`, objectFit: `contain` }} alt="" />
                                                <span>{number2}</span>
                                            </div>
                                            <div className='btn-number' style={{ height: windowHeight * 0.2125, width: windowWidth * 0.1 }}>
                                                <img src="https://file.tengroup.com.vn/wwwroot/18042022/8Eer8JnvrHab55bIpqkMbUSEgqdV6wUFtZtRACgVVAEF7kFJDanljggRtChLSRwAerURYV8PrI0WcLBd.png" style={{ height: `100%`, width: `100%`, objectFit: `contain` }} alt="" />
                                                <span>{number3}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row" style={{ paddingTop: `3.75%` }}>
                                    <div className="col-md-12">
                                        <div className="container-fluid">                                     
                                            <table id="tblCustomer1" className="table">
                                                <thead>
                                                    <tr>
                                                        <th style={{ width: `12.5%` }}>
                                                            <b>MÃ SỐ</b>
                                                        </th>
                                                        <th style={{ width: `30%` }}>
                                                            <b>TÊN KHÁCH HÀNG</b>
                                                        </th>
                                                        <th style={{ width: `20%` }}>
                                                            <b>TỈNH THÀNH</b>
                                                            </th>
                                                        <th style={{ width: `37.5%` }}>
                                                            <b>ĐỊA CHỈ</b>
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {html}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                );           
            default:
                return null;
        }
    }

    return (
        <React.Fragment>
            {renderBody()}
        </React.Fragment>
    );
}

const mapStateToProps = (state: any) => {
    //console.log(state.award.award);
    return {
        checkaward: state.checkaward.award,
        award: state.award.award,
        //isNew: state.award.isNew,
        setting: state.award.setting,
        listCode: state.generate.listCode,
        loading: state.loading.status,
        detailevent: state.event.detailevent,
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        ShowError: (severity: string, summary: string, detail: string) =>
            dispatch(actions.ShowError(severity, summary, detail)),
        GetSettingAward: (awardId: number) => dispatch(actions.GetSettingAward(awardId)),
        CheckAward: () => dispatch(actions.CheckAward()),
        GenerateCode: (event: any, top: number, awardId: number) => dispatch(actions.GenerateCode(event, top, awardId))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Pages as any);