import * as types from '../contains/actionTypes';
//import {enCryptData,enCryptDataClient,GetProfileString} from '../../authentication/crypto';
import {
    GET_SETTING_AWARD,
    LIST_AWARD,
    LIST_AWARD_FULL,
    CHECK_AWARD
} from '../contains/api';
import * as Status from '../../common/enum';
import * as config from '../../common/config';

const _ = require("lodash");
const axios = require("axios");

export const changeLoading = (loading: boolean) => {
    return {
        type: types.CHANGE_LOADING,
        loading
    };
}

export const AwardInit = () => {
    return {
        type: types.AWARD_INI
    };
};

export const AwardSuccess = (award: any, setting: any, backgroundUrl: string) => {
    return {
        type: types.AWARD_SUCCESS,
        award,
        setting,
        backgroundUrl
    }
}

export const ManualCodeSuccess = (listManualCode: any) => {
    return {
        type: types.MANUAL_CODE_SUCCESS,
        listManualCode
    }
}

export const GenerateSuccess = (listCode: any) => {
    return {
        type: types.GENERATE_SUCCESS,
        listCode
    }
}

export const ListAwardSuccess = (listAward: any) => {
    return {
        type: types.LIST_AWARD_SUCCESS,
        listAward
    }
}

export const UpdateIsNew = (isNew: boolean) => {
    return {
        type: types.UPDATE_AWARD_NEW,
        isNew
    }
}


export const ListAwardFullSuccess = (listAwardFull: any) => {
    return {
        type: types.LIST_AWARD_FULL_SUCCESS,
        listAwardFull
    }
}

export const showError = (severity: any, summary: any, detail: any) => {
    return {
        type: types.ERROR_SUCCESS,
        severity,
        summary,
        detail
    }
}

export function CheckAward() {
    return async (dispatch: any) => {
        dispatch(showError(null, null, null))
        axios.get(CHECK_AWARD)
            .then((response: any) => {
                if (response.status === Status.STATUS_RESPONSE_OK) {
                    const data: any = response.data;
                    const {
                        award
                    } = data;

                    if (Object.keys(award).length > 0) {
                        const {
                            id
                        } = award;
                        dispatch(GetSettingAward(id));
                    }                   
                }
            })
            .catch((error: any) => {
                dispatch(showError("error", "Lỗi", error.toString()));
            })
    }
}

export function GetSettingAward(awardId: number) {
    return async (dispatch: any) => {
        dispatch(showError(null, null, null))
        //dispatch(GenerateSuccess([]));
        axios.get(GET_SETTING_AWARD, {
            params: {
                awardId: awardId,
                eventId: config.EVENT_ID
            }
        })
            .then((response: any) => {
                if (response.status === Status.STATUS_RESPONSE_OK) {
                    const data: any = response.data;
                    const {
                        award,
                        setting,
                        listCode,
                        backgroundUrl,
                    } = data;

                    //console.log(data);

                    dispatch(AwardSuccess(award, setting, backgroundUrl));

                    const {
                        amount
                    } = award;

                    dispatch(ManualCodeSuccess(listCode));

                    //console.log(listCode);

                    let array: any = [];

                    if (listCode.length > 0) {
                        //array = _.cloneDeep(listCode);
                        listCode.forEach((item: any) => {
                            array.push({ code: item.code })
                        });
                    }

                    for (var i = 1; i <= (amount - listCode.length); i++) {
                        array.push({ code: "" })
                    }

                    //console.log(array);

                    dispatch(GenerateSuccess(array));
                    dispatch(changeLoading(false));
                }
            })
            .catch((error: any) => {
                alert(error.toString());
                dispatch(changeLoading(false));
            })
    }
}

export function GetListAward() {
    return async (dispatch: any) => {
        dispatch(changeLoading(true));
        dispatch(showError(null, null, null))
        axios.get(LIST_AWARD)
            .then((response: any) => {
                if (response.status === Status.STATUS_RESPONSE_OK) {
                    const data: any = response.data;
                    const {
                        listAward
                    } = data;
                    dispatch(ListAwardSuccess(listAward))
                    dispatch(changeLoading(false));
                }
            })
            .catch((error: any) => {
                dispatch(showError("error", "Lỗi", error.toString()));
                dispatch(changeLoading(false));
            })
    }
}

export function GetListAwardFull() {
    return async (dispatch: any) => {
        dispatch(changeLoading(true));
        dispatch(showError(null, null, null))
        axios.get(LIST_AWARD_FULL)
            .then((response: any) => {
                if (response.status === Status.STATUS_RESPONSE_OK) {
                    const data: any = response.data;
                    const {
                        listAward
                    } = data;
                    dispatch(ListAwardFullSuccess(listAward))
                    dispatch(changeLoading(false));
                }
            })
            .catch((error: any) => {
                dispatch(showError("error", "Lỗi", error.toString()));
                dispatch(changeLoading(false));
            })
    }
}

export function UpdateSplashScreen(isShow: boolean) {
    return async (dispatch: any) => {
        dispatch(UpdateIsNew(isShow));
    }
}