import React from 'react';
import Error from './Error';

const Layout = (props: any) => {
    const {
        backgroundUrl
    } = props;

    const styles = {
        backgroundImage: `url(${backgroundUrl})`,
        zIndex: 1
    }

    return (
        <div className="event-background" style={styles}>
            {props.children}
            <Error />
        </div>
    )
}

export default Layout;