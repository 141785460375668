import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Route, Redirect, Switch, withRouter } from 'react-router-dom';
import * as router from './common/router';
import * as config from './common/config';

import Layout from './components/Layout';
import LayoutAdmin from './components/LayoutAdmin';
import LayoutCallcenter from './components/LayoutCallcenter';
//import Home from './pages/Home';
import Admin from './pages/Admin/AdminPage';
import InputCode from './pages/Callcenter/index';
import Led from './pages/Led/index';
import LedSpecial from './pages/Led/special';
import Login from './pages/Login';
import * as actions from '../src/reducer/actions/index';
const _ = require("lodash");
//import Page404 from './pages/404'

function App(props: any) {

    if (process.env.NODE_ENV === "production") {
        console.log = () => { };
        console.error = () => { };
        console.debug = () => { };
    }

    const {
        roleId,
        detailevent,
        backgroundUrl,
        GetDetailEvent,
        UpdateEventResolution,
        GetListAreaByEventId
    } = props;

    //const [backgroundUrl, setBackgroundUrl] = useState<string>("");

    useEffect(() => {
        GetDetailEvent(config.EVENT_ID);
        GetListAreaByEventId(config.EVENT_ID);
    }, [])

    useEffect(() => {


        if (detailevent !== undefined && detailevent !== null && Object.keys(detailevent).length > 0) {
            const {
                width,
                height
            } = detailevent;

            if (window.innerWidth !== width || window.innerHeight !== height) {
                updateResolution(window.innerWidth, window.innerHeight);
            }

            //console.log(window.innerWidth, window.innerHeight);
        }
    }, [detailevent]);

    //useEffect(() => {
    //    //console.log(listArea);
    //    if (listArea !== undefined && listArea !== null && listArea.length > 0) {
    //        let area = _.filter(listArea, (item: any) => {
    //            return item.isUsed
    //        });

    //        const {
    //            imageUrl
    //        } = area[0];

    //        setBackgroundUrl(imageUrl);
    //    }
    //}, [listArea])

    const updateResolution = (width: any, height: any) => {
        const objUpdate = {
            eventId: config.EVENT_ID,
            width: width,
            height: height
        }

        if (roleId === undefined || roleId === null) {
            UpdateEventResolution(objUpdate);
        }
    }


    let route: any = null;


    switch (roleId) {
        case "1":
            route = (
                <LayoutAdmin>
                    <Switch>
                        <Route exact path={`${router.Led}`} render={(props: any) => <Led {...props} />} />
                        <Route exact path={`${router.LedSpecial}`} render={(props: any) => <LedSpecial {...props} />} />
                        <Route exact path={`${router.Admin}`} component={Admin} />
                        <Route exact path={`${router.InputCode}`} component={InputCode} />
                        <Redirect to={router.Admin} />
                    </Switch>
                </LayoutAdmin>
            )
            break;
        case "2":
            //console.log(backgroundUrl)
            route = (
                <LayoutCallcenter backgroundUrl={backgroundUrl}>
                    <Switch>
                        <Route exact path={`${router.InputCode}`} component={InputCode} />
                        <Redirect to={router.InputCode} />
                    </Switch>
                </LayoutCallcenter>
            );
            break;
        default:
            //<Route exact path={`${router.CustomerAward}/:id`} component={ListCustomerAward} />
            //<Route exact path={`${router.Home}`} component={Home} />
            route = (
                <Layout backgroundUrl={backgroundUrl}>
                    <Switch>
                        <Route exact path={`${router.Login}`} component={Login} />
                        <Route exact path={`${router.Led}`} render={(props: any) => <Led {...props} />} />
                        <Route exact path={`${router.LedSpecial}`} render={(props: any) => <LedSpecial {...props} />} />
                        <Redirect to={router.Led} />
                    </Switch>
                </Layout>
            );
            break;
    }



    return (
        <>
            {route}
        </>
    );
};

const mapStateToProps = (state: any) => {
    //console.log(state)
    return {
        roleId: state.auth.roleId,
        detailevent: state.event.detailevent,
        backgroundUrl: state.award.backgroundUrl,
        listArea: state.area.listArea
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        GetDetailEvent: (eventId) => dispatch(actions.GetDetailEvent(eventId)),
        UpdateEventResolution: (obj: any) => dispatch(actions.UpdateEventResolution(obj)),
        GetListAreaByEventId: (eventId) => dispatch(actions.ListAreaByEventId(eventId)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(App));