import jwt from 'jsonwebtoken';

import {
    REACT_APP_SECRET_KEY_CLIENT,
    REACT_APP_JWT,
    REACT_APP_SECRET_KEY
    } from '../common/config';
var CryptoJS = require("crypto-js");

export function enCryptData(decipherText:any) {

    var ciphertext = CryptoJS.AES.encrypt(JSON.stringify(decipherText), REACT_APP_SECRET_KEY).toString();
  
    return ciphertext;
}


export function deCryptData(cipherText:string) {
    var bytes  = CryptoJS.AES.decrypt(cipherText, REACT_APP_SECRET_KEY);
    var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    return decryptedData;
}

export function enCryptDataClient(decipherText:any) {
    var ciphertext = CryptoJS.AES.encrypt(JSON.stringify(decipherText), REACT_APP_SECRET_KEY_CLIENT).toString();
    return ciphertext;
}




export function deCryptDataClient(cipherText:string) {
    var bytes  = CryptoJS.AES.decrypt(cipherText, REACT_APP_SECRET_KEY_CLIENT);
    var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    return decryptedData;
}

export function GetProfile(){
    let token = deCryptToken()

    //console.log(jwt.decode(token));

    if(token){
        let decoded: any = jwt.decode(token);
        let obj = deCryptData(decoded.data);
        return obj;
    }
    return null;
   
}


export function GetProfileString(token: any){
    let decoded: any = jwt.decode(token);
    //console.log(decoded, token);
    let obj = deCryptData(decoded.data);
    return obj;
}

export function GetJWT(){
    let jwt = REACT_APP_JWT as string;
    let browserToken = localStorage.getItem(jwt);
    return browserToken;
}

export function deCryptToken(){
    let browserToken = localStorage.getItem(REACT_APP_JWT as string) as string;
    if(browserToken){
        return deCryptDataClient(browserToken)
    }
    return null
    
}