import * as types from '../contains/actionTypes';
//import {enCryptData,enCryptDataClient,GetProfileString} from '../../authentication/crypto';
import {GENERATE_CODE} from '../contains/api';
import * as Status from '../../common/enum';
import * as config from '../../common/config';
import {GetSettingAward} from './award'
const axios = require("axios");

export const changeLoading = (loading: boolean) => {
    return {
        type: types.CHANGE_LOADING,
        loading
    };
}

export const GenerateInit = () => {
    return {
        type: types.GENERATE_INI
    };
};

export const GenerateSuccess = (listCode: any) => {
    return {
        type: types.GENERATE_SUCCESS,
        listCode
    }
}

export const showError = (severity: any, summary: any, detail: any) => {
    return {
        type: types.ERROR_SUCCESS,
        severity,
        summary,
        detail
    }
}

export function GenerateCode(event: any, top: number, awardId: number) {
    return async (dispatch: any) => {
        dispatch(changeLoading(true));
        dispatch(showError(null, null, null))
        const btn = event.target;
        (btn as any).setAttribute("disabled", true);
        axios.get(GENERATE_CODE, {
            params: {
                top: top,
                awardId: awardId
            }
        })
        .then((response: any) => {
            if (response.status === Status.STATUS_RESPONSE_OK) {
                const data: any = response.data;
                const {
                    listCode
                } = data;
                dispatch(GenerateSuccess(listCode));
                dispatch(GetSettingAward(awardId));
                dispatch(changeLoading(false));    
                (btn as any).removeAttribute("disabled");
            }
        })
        .catch((error: any) => {
            dispatch(showError("error", "Lỗi", error.toString()));
            dispatch(changeLoading(false));
        }) 
    }
}