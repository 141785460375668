import * as types from '../contains/actionTypes';
//import {enCryptData,enCryptDataClient,GetProfileString} from '../../authentication/crypto';
import {
    LIST_CUSTOMER_AWARD
} from '../contains/api';
import * as Status from '../../common/enum';
const axios = require("axios");

export const changeLoading = (loading: boolean) => {
    return {
        type: types.CHANGE_LOADING,
        loading
    };
}

export const CustomerAwardInit = () => {
    return {
        type: types.CUSTOMER_AWARD_INI
    };
};

export const CustomerAwardSuccess = (award: any, listCustomerAward: any) => {
    return {
        type: types.CUSTOMER_AWARD_SUCCESS,
        award,
        listCustomerAward
    }
}

export const CustomerAwardPaging = (first: number, pageNo: number, pageRows: number, totalRecords: number) => {
    return {
        type: types.CUSTOMER_AWARD_PAGING,
        first,
        pageNo,
        pageRows,
        totalRecords
    }
}

export const showError = (severity: any, summary: any, detail: any) => {
    return {
        type: types.ERROR_SUCCESS,
        severity,
        summary,
        detail
    }
}



export function ListCustomerAward(objParam: any) {
    return async (dispatch: any) => {
        dispatch(changeLoading(true));
        dispatch(showError(null, null, null))
        axios.post(LIST_CUSTOMER_AWARD, objParam)
        .then((response: any) => {
            // console.log(response);
            if (response.status === Status.STATUS_RESPONSE_OK) {
                const data: any = response.data;
                //console.log(data);
                let {
                    award,
                    listCustomer
                } = data;

                let rowIndex: number = 1;

                Array.from(listCustomer).forEach((item: any) => {
                    item["stt"] = ((objParam.PageNo - 1) * objParam.PageSize) + rowIndex;
                    rowIndex++;
                })

                let totalRecords = 0;
                if (Array.from(listCustomer).length > 0) {
                    totalRecords = listCustomer[0].countRow;
                }

                dispatch(CustomerAwardSuccess(award, listCustomer));
                dispatch(CustomerAwardPaging((objParam.PageNo - 1) * objParam.PageSize, objParam.PageNo, objParam.PageSize, totalRecords));
                dispatch(changeLoading(false));
            }
            dispatch(changeLoading(false));
        })
        .catch((error: any) => {
            dispatch(showError("error", "Lỗi", error.toString()));
            dispatch(changeLoading(false));
        }) 
    }
}