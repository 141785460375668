import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../reducer/actions/index';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
//import { InputNumber } from 'primereact/inputnumber';
//import { InputText } from 'primereact/inputtext';
//import ColorPicker from 'rc-color-picker';

import {
    LIST_BOX,
    LIST_AWARD1,
    UPDATE_SHOW_BOX,
    UPDATE_SHOW_AWARD
} from '../../reducer/contains/api';

import { confirmDialog } from 'primereact/confirmdialog';
import * as config from '../../common/config';
import 'rc-color-picker/assets/index.css';
import * as Status from '../../common/enum';

const _ = require("lodash");
const axios = require("axios");

const MobileView = (props: any) => {
    const {
        //listArea,
        //listAreaAward,
        //GetListAreaAward,
        ShowError
    } = props;

    const [listBox, setListBox] = useState([]);
    const [boxId, setBoxId] = useState(0);
    const [listAward, setListAward] = useState<any>([]);
    const [awardId, setAwardId] = useState(0);
    const [disableButton1, setDisabledButton1] = useState(true);
    const [disableButton2, setDisabledButton2] = useState(true);


    const GetListBox = () => {
        axios.get(LIST_BOX)
            .then((response: any) => {
                if (response.status === Status.STATUS_RESPONSE_OK && response.data.status === Status.STATUS_RESPONSE_OK) {
                    setListBox(response.data.result);
                    let listBox = response.data.result;
                    let idx = listBox.findIndex(x => x.isHome === true);
                    //console.log(idx);
                    if (idx > -1) {
                        setBoxId(listBox[idx].boxId);
                        GetListGift(listBox[idx].boxId);
                    }
                }
            })
            .catch((error: any) => {
                console.log(error);
            })
    }

    const GetListGift = (boxId) => {
        axios.get(`${LIST_AWARD1}?boxId=${boxId}`)
            .then((response: any) => {
                if (response.status === Status.STATUS_RESPONSE_OK && response.data.status === Status.STATUS_RESPONSE_OK) {
                    setListAward(response.data.result);
                    let listAward = response.data.result;
                    let idx = listAward.findIndex(x => x.isHome === true && x.boxId === boxId);
                    console.log(listAward);
                    if (idx > -1) {
                        setAwardId(listAward[idx].awardId);
                    } else {
                        setAwardId(listAward[0].awardId);
                    }
                }
            })
            .catch((error: any) => {
                console.log(error);
            })
    }

    useEffect(() => {
        GetListBox();
    }, [])

    const onChangeListBox = (e) => {
        setBoxId(e.value);
        GetListGift(e.value);
        setAwardId(0);
        setDisabledButton1(false);
    }

    const onChangeAward = (e) => {
        setAwardId(e.value);
        setDisabledButton2(false);
    }

    const updateSetting = (e) => {
        e.preventDefault();
        confirmDialog({
            message: 'Bạn có muốn thay đổi bảng đang hiển thị không ?',
            header: 'Bảng đang hiển thị',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                axios.get(`${UPDATE_SHOW_BOX}?boxId=${boxId}`)
                    .then((response: any) => {
                        if (response.status === Status.STATUS_RESPONSE_OK) {
                            alert('Cập nhật thành công !');
                            setDisabledButton1(true);
                            //let listAward = response.data.result;
                            let idx = listAward.findIndex(x => x.isHome === true && x.boxId === boxId);
                            //console.log(listAward);
                            if (idx > -1) {
                                setAwardId(listAward[idx].awardId);
                            } else {
                                axios.get(`/api/event/updateshowaward?awardId=${listAward[0].awardId}`)
                                    .then((response: any) => {
                                        if (response.status === Status.STATUS_RESPONSE_OK) {
                                            /*alert('Cập nhật thành công !')*/
                                            setAwardId(listAward[0].awardId);
                                            setDisabledButton2(true);
                                        }
                                    })
                                    .catch((error: any) => {
                                        alert(`Lỗi ! ${error.toString()}`)
                                        //ShowError("error", "Lỗi", error.toString());
                                    })
                            }
                        }
                    })
                    .catch((error: any) => {
                        alert(`Lỗi ! ${error.toString()}`)
                        //ShowError("error", "Lỗi", error.toString());
                    })
            },
            reject: () => {
                return;
            }
        });
    }

    const updateAward = (e) => {
        e.preventDefault();
        confirmDialog({
            message: 'Bạn có muốn thay đổi giải đang hiển thị không ?',
            header: 'Giải đang hiển thị',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                axios.get(`${UPDATE_SHOW_AWARD}?awardId=${awardId}`)
                    .then((response: any) => {
                        if (response.status === Status.STATUS_RESPONSE_OK) {
                            alert('Cập nhật thành công !')
                            setDisabledButton2(true);
                        }
                    })
                    .catch((error: any) => {
                        alert(`Lỗi ! ${error.toString()}`)
                        //ShowError("error", "Lỗi", error.toString());
                    })
            },
            reject: () => {
                return;
            }
        });
    }

    const templateBox = (option) => {
        //console.log(option);
        return (
            <div>{`${option.boxName} (${option.shiftName}) `}</div>
        );
    }

    const templateBoxValue = (option, props) => {
        if (option) {
            return (
                <div>{`${option.boxName} (${option.shiftName}) `}</div>
            );
        }
        return (
            <span>
                {props.placeholder}
            </span>
        );
    }

    const logOut = () => {
        localStorage.clear();
        window.location.href = '/'
    }

    return (
        <React.Fragment>
            <div className="container" style={{ maxWidth: 700, marginTop: 100 }}>
                <div className="p-grid">
                    <div className="col-12 text-center" style={{ paddingTop: 30, paddingBottom: 30 }}>
                        <h4 style={{ margin: 0, color: "#000" }}>CƠ CẤU GIẢI THƯỞNG</h4>
                    </div>
                </div>
                 <div className="p-grid">
                    <div className="p-col-12">
                        <span style={{ margin: 0, color: "#000", fontWeight: 'normal' }}>BẢNG ĐANG ĐƯỢC CHỌN</span>
                    </div>
                </div>
                <div className="p-grid">
                    <div className="p-col-9">
                        <Dropdown
                            optionValue="boxId"
                            optionLabel="boxName"
                            value={boxId}
                            options={listBox}
                            onChange={onChangeListBox}
                            placeholder="Chọn bảng..."
                            itemTemplate={templateBox}
                            valueTemplate={templateBoxValue}
                            style={{ width: "100%" }}
                        />
                    </div>
                    <div className="p-col-3">
                        <Button label="THAY ĐỔI" icon="pi pi-check" className="btn-block p-button-success" disabled={disableButton1} onClick={updateSetting} />
                    </div>
                </div> 
                <div className="p-grid">
                    <div className="p-col-12">
                        <span style={{ margin: 0, color: "#000", fontWeight: 'normal' }}>GIẢI ĐANG ĐƯỢC HIỂN THỊ: </span>
                    </div>
                </div>
                <div className="p-grid">
                    <div className="p-col-9">
                        <Dropdown
                            optionValue="awardId"
                            optionLabel="name"
                            value={awardId}
                            options={listAward}
                            onChange={onChangeAward}
                            placeholder="Chọn giải thưởng..."
                            style={{ width: "100%" }}
                        />
                    </div>
                    <div className="p-col-3">
                        <Button label="THAY ĐỔI" icon="pi pi-check" className="btn-block p-button-success" disabled={disableButton2} onClick={updateAward} />
                    </div>
                </div>
                <div className="p-grid" style={{ marginTop: 40 }}>
                    <div className="p-col-12 text-center">
                        <Button label="ĐĂNG XUẤT" className="p-button-danger" onClick={logOut} />
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

const mapStateToProps = (state: any) => {
    //console.log(state)
    return {
        listArea: state.area.listArea,
        listAreaAward: state.area.listAreaAward,
        loading: state.loading.status
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        ShowError: (severity: string, summary: string, detail: string) =>
            dispatch(actions.ShowError(severity, summary, detail)),
        GetListAreaAward: (eventId: number, areaId: number) => dispatch(actions.ListAreaAward(eventId, areaId))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MobileView as any);