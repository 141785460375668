
const dotenv = require('dotenv');
dotenv.config();
// capture the environment variables the application needs
export const {
    REACT_APP_PAGE_SIZE,
    REACT_APP_MAX_WIDTH,
    REACT_APP_MAX_HEIGHT,
    REACT_APP_SECRET_KEY,
    REACT_APP_SECRET_KEY_CLIENT,
    REACT_APP_JWT,
    REACT_APP_JWT_REFESH
} = process.env;

export function GetURL() {
    var enviroment = 1;
    var baseUrlLink = 'http://localhost:3012';
    switch (enviroment) {
        case 0:
            baseUrlLink = 'http://localhost:3012';
            break;
        case 1:
            baseUrlLink = 'http://livesumitomo.tengroup.com.vn';
            break;
        default:
            baseUrlLink = '';
            break;
    }
    return baseUrlLink;
}

export const SERVICE_URI = GetURL();
export const TIME_REFRESH = 3000;
export const CACHE_ROLEID = "qMXcfPMOCv";
export const OLD_RESULT = 'kGYlFhSOlT';
export const EVENT_ID = 2;




