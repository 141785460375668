import * as types from '../contains/actionTypes';

export const error_ini = () => {
    return {
        type: types.ERROR_INI
    };
};

export const ShowError = (severity: any, summary: any, detail: any) => {
    return {
        type: types.ERROR_SUCCESS,
        severity,
        summary,
        detail
    }
}
