import * as types from '../contains/actionTypes';
//import {enCryptData,enCryptDataClient,GetProfileString} from '../../authentication/crypto';
import {
    LIST_EVENT_AWARD,
    LIST_EVENT_AWARD_FULL
} from '../contains/api';
import * as Status from '../../common/enum';
import * as config from '../../common/config';

const axios = require("axios");

export const changeLoading = (loading: boolean) => {
    return {
        type: types.CHANGE_LOADING,
        loading
    };
}

export const EventAwardInit = () => {
    return {
        type: types.EVENT_AWARD_INI
    };
};

export const EventListAwardSuccess = (listEventAward: any) => {
    return {
        type: types.EVENT_LIST_AWARD_SUCCESS,
        listEventAward
    }
}

export const ListEventAwardFullSuccess = (listEventAwardFull: any) => {
    return {
        type: types.EVENT_LIST_AWARD_FULL_SUCCESS,
        listEventAwardFull
    }
}

export const showError = (severity: any, summary: any, detail: any) => {
    return {
        type: types.ERROR_SUCCESS,
        severity,
        summary,
        detail
    }
}



export function GetListEventAward(eventId: number) {
    return async (dispatch: any) => {
        dispatch(changeLoading(true));
        dispatch(showError(null, null, null))
        axios.get(LIST_EVENT_AWARD, {
            params: {
                eventId: eventId
            }
        })
            .then((response: any) => {
                if (response.status === Status.STATUS_RESPONSE_OK) {
                    const data: any = response.data;
                    const {
                        listAward
                    } = data;
                    dispatch(EventListAwardSuccess(listAward))
                    dispatch(changeLoading(false));
                }
            })
            .catch((error: any) => {
                dispatch(showError("error", "Lỗi", error.toString()));
                dispatch(changeLoading(false));
            })
    }
}

export function GetListEventAwardFull(eventId: number) {
    //console.log(eventId);
    return async (dispatch: any) => {
        dispatch(changeLoading(true));
        dispatch(showError(null, null, null))
        axios.get(LIST_EVENT_AWARD_FULL, {
            params: {
                eventId: eventId
            }
        })
            .then((response: any) => {
                if (response.status === Status.STATUS_RESPONSE_OK) {
                    const data: any = response.data;
                    const {
                        listAward
                    } = data;
                    //console.log(data)
                    dispatch(ListEventAwardFullSuccess(listAward))
                    dispatch(changeLoading(false));
                }
            })
            .catch((error: any) => {
                dispatch(showError("error", "Lỗi", error.toString()));
                dispatch(changeLoading(false));
            })
    }
}
