import * as actionTypes from '../contains/actionTypes';
const initialState: any = {
    award: null,
    listCustomerAward: [],
    message: "",
    /*Ph�n trang*/
    first: 0,
    totalRecords: 0,
    pageNo: 0,
    pageRows: 100
}

const reducer = (state = initialState , action:any) => {
    switch (action.type) {
        case actionTypes.CUSTOMER_AWARD_INI:
            return {
                ...state
            };
        case actionTypes.CUSTOMER_AWARD_SUCCESS:
            return {
                ...state,
                award: action.award,
                listCustomerAward: action.listCustomerAward
            }
        case actionTypes.CUSTOMER_AWARD_PAGING:
            return {
                ...state,
                first: action.first,
                pageNo: action.pageNo,
                pageRows: action.pageRows,
                totalRecords: action.totalRecords
            }
        case actionTypes.CUSTOMER_AWARD_FAIL:
            return {
                ...state,
                message: action.message
            }
        default:
            return state;
    }
}

export default reducer;