import * as actionTypes from '../contains/actionTypes';
const initialState: any = {
    listManualCode: [],
    message: "",
    /*Ph�n trang*/
    first: 0,
    totalRecords: 0,
    pageNo: 0,
    pageRows: 100
}

const reducer = (state = initialState, action: any) => {
    switch (action.type) {
        case actionTypes.MANUAL_CODE_INI:
            return {
                ...state
            };
        case actionTypes.MANUAL_CODE_SUCCESS:
            return {
                ...state,
                listManualCode: action.listManualCode
            }
        case actionTypes.MANUAL_CODE_PAGING:
            return {
                ...state,
                first: action.first,
                pageNo: action.pageNo,
                pageRows: action.pageRows,
                totalRecords: action.totalRecords
            }
        case actionTypes.MANUAL_CODE_FAIL:
            return {
                ...state,
                message: action.message
            }
        default:
            return state;
    }
}

export default reducer;