import * as actionTypes from '../contains/actionTypes';
const initialState: any = {
    listEventAward: [],
    listEventAwardFull: [],
    //isNew: false,
    message: ""
}

const reducer = (state = initialState , action:any) => {
    switch (action.type) {
        case actionTypes.EVENT_AWARD_INI:
            return {
                ...state
            };
        case actionTypes.EVENT_LIST_AWARD_SUCCESS:
            return {
                ...state,
                listEventAward: action.listEventAward
            }
        case actionTypes.EVENT_LIST_AWARD_FULL_SUCCESS:
            return {
                ...state,
                listEventAwardFull: action.listEventAwardFull
            }
        case actionTypes.AWARD_FAIL:
            return {
                ...state,
                message: action.message
            }
        default:
            return state;
    }
}

export default reducer;