import * as types from '../contains/actionTypes';
//import {enCryptData,enCryptDataClient,GetProfileString} from '../../authentication/crypto';
import {
    DETAIL_EVENT,
    UPDATE_EVENT_RESOLUTION
} from '../contains/api';
import * as Status from '../../common/enum';
import * as config from '../../common/config';

const axios = require("axios");

export const changeLoading = (loading: boolean) => {
    return {
        type: types.CHANGE_LOADING,
        loading
    };
}

export const EventInit = () => {
    return {
        type: types.DETAIL_EVENT_INI
    };
};

export const EventSuccess = (detailevent: any) => {
    return {
        type: types.DETAIL_EVENT_SUCCESS,
        detailevent
    }
}

export const showError = (severity: any, summary: any, detail: any) => {
    return {
        type: types.ERROR_SUCCESS,
        severity,
        summary,
        detail
    }
}



export function GetDetailEvent(eventId: number) {
    return async (dispatch: any) => {
        dispatch(showError(null, null, null))
        //dispatch(GenerateSuccess([]));
        axios.get(DETAIL_EVENT, {
            params: {
                eventId: eventId
            }
        })
            .then((response: any) => {
                if (response.status === Status.STATUS_RESPONSE_OK) {
                    const data: any = response.data;
                    const {
                        detailevent
                    } = data;

                    dispatch(EventSuccess(detailevent));
                    dispatch(changeLoading(false));
                }
            })
            .catch((error: any) => {
                alert(error.toString());
                dispatch(changeLoading(false));
            })
    }
}

export function UpdateEventResolution(objUpdate: any) {
    return async (dispatch: any) => {
        dispatch(changeLoading(true));
        dispatch(showError(null, null, null))
        axios.post(UPDATE_EVENT_RESOLUTION, objUpdate)
            .then((response: any) => {
                if (response.status === Status.STATUS_RESPONSE_OK) {
                    dispatch(GetDetailEvent(objUpdate.eventId))
                    dispatch(changeLoading(false));
                }
            })
            .catch((error: any) => {
                dispatch(showError("error", "Lỗi", error.toString()));
                dispatch(changeLoading(false));
            })
    }
}