import * as actionTypes from '../contains/actionTypes';
const initialState: any = {
    detailevent: null,
    message: ""
}

const reducer = (state = initialState , action:any) => {
    // console.log(action);
    switch (action.type) {
        case actionTypes.DETAIL_EVENT_INI:
            return {
                ...state
            };
        case actionTypes.DETAIL_EVENT_SUCCESS:            
            return {
                ...state,
                detailevent: action.detailevent
            }
        case actionTypes.DETAIL_EVENT_FAIL:
            return {
                ...state,
                message: action.message
            }
        default:
            return state;
    }
}

export default reducer;