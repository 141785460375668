import { SERVICE_URI } from '../../common/config';

//AreaController
export const AREA_USED = `${SERVICE_URI}/api/Area/AreaUsed`;
export const LIST_AREA_EVENT = `${SERVICE_URI}/api/Area/ListAreaByEventId`;
export const UPDATE_AREA_USED = `${SERVICE_URI}/api/Area/UpdateAreaIsUsed`;
export const LIST_AREA_AWARD = `${SERVICE_URI}/api/Area/GetListAreaAward`;


//AuthController
export const LOGIN = `${SERVICE_URI}/api/auth/authenticate`;

//AwardController
export const GET_SETTING_AWARD = `${SERVICE_URI}/api/award/GetSettingAward`;
export const LIST_AWARD = `${SERVICE_URI}/api/award/GetListAward`;
export const LIST_AWARD_FULL = `${SERVICE_URI}/api/award/getlistawardfull`;
export const CHECK_AWARD = `${SERVICE_URI}/api/award/checkaward`;
export const GENERATE_CODE = `${SERVICE_URI}/api/award/generateCode`;
export const UPDATE_AWARD_LED = `${SERVICE_URI}/api/award/updateShowLed`;
export const RESET_AWARD = `${SERVICE_URI}/api/award/resetAward`;
export const UPDATE_SETTING = `${SERVICE_URI}/api/award/updatesetting`;

//CustomerAwardController
export const LIST_CUSTOMER_AWARD = `${SERVICE_URI}/api/customeraward/listcustomeraward`;

//EventAwardController
export const LIST_EVENT_AWARD = `${SERVICE_URI}/api/EventAward/GetListEventAward`;
export const LIST_EVENT_AWARD_FULL = `${SERVICE_URI}/api/EventAward/GetListEventAwardFull`;


//EventController
export const DETAIL_EVENT = `${SERVICE_URI}/api/event/detailEvent`;
export const UPDATE_EVENT_RESOLUTION = `${SERVICE_URI}/api/event/updateResolution`;

//ManualController
export const LIST_MANUAL_AWARD = `${SERVICE_URI}/api/Manual/GetListManualAward`;
export const LIST_MANUAL_AWARD_PAGING = `${SERVICE_URI}/api/Manual/GetListManualAwardPaging`;
export const UPDATE_MANUAL_CODE = `${SERVICE_URI}/api/Manual/UpdateManualCode`;

//ReportController
export const REPORT_ALL = `${SERVICE_URI}/api/report/reportall`;

export const LIST_BOX = `${SERVICE_URI}/api/event/listbox`;
export const LIST_AWARD1 = `${SERVICE_URI}/api/event/listaward`;
export const UPDATE_SHOW_BOX = `${SERVICE_URI}/api/event/updateshowbox`;
export const UPDATE_SHOW_AWARD = `${SERVICE_URI}/api/event/updateshowaward`;
export const LIST_CUSTOMER = `${SERVICE_URI}/api/event/listcustomeraward`;
export const ADD_CODE = `${SERVICE_URI}/api/manual/UpdateManualCode`;
export const DEL_CODE = `${SERVICE_URI}/api/manual/DelCode`;

export const ADD_MANUAL_CODE = `${SERVICE_URI}/api/manual/updatecodelength`;
export const GET_LIST_NUMBER = `${SERVICE_URI}/api/manual/listnumers`;
export const SET_LIST_NUMBER = `${SERVICE_URI}/api/manual/setnumber`;
export const CLR_LIST_NUMBER = `${SERVICE_URI}/api/manual/clearNumber`;
export const INFO_USER = `${SERVICE_URI}/api/manual/detailcustomer`;



