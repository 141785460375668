import * as types from '../contains/actionTypes';
import {enCryptDataClient} from '../../authentication/crypto';
import { LOGIN } from '../contains/api';
import * as Status from '../../common/enum';
import * as router from '../../common/router';
import * as config from '../../common/config';

import { REACT_APP_JWT, REACT_APP_JWT_REFESH } from '../../common/config';

const axios = require("axios");

export const auth_ini = () => {
    return {
        type: types.AUTH_INI
    };
};

export const auth_success = (token: string, profile: any, roleId: string) => {
    return {
        type: types.AUTH_SUCCESS,
        token,
        profile,
        roleId
    }
}


export const changeLoading = (loading: boolean) => {
    return {
        type: types.CHANGE_LOADING,
        loading
    };
}



export const showError = (severity: any, summary: any, detail: any) => {
    return {
        type: types.ERROR_SUCCESS,
        severity,
        summary,
        detail
    }
}


export function Login (objLogin: any) {
    return async (dispatch: any) => {
        dispatch(showError(null, null, null));
        axios.post(LOGIN, objLogin)
        .then((response: any) => {
            const data: any = response.data;  
            if (data.status !== Status.STATUS_RESPONSE_OK) {  
                dispatch(auth_ini());
                dispatch(showError('error', 'Lỗi', data.message));
            } else {
                localStorage.setItem(REACT_APP_JWT as string, enCryptDataClient(data.token));
                localStorage.setItem(config.CACHE_ROLEID, data.roleId);
                //console.log(GetProfileString("eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1bmlxdWVfbmFtZSI6IjEiLCJuYmYiOjE2MDUxMDEwMjgsImV4cCI6MTYwNTcwNTgyOCwiaWF0IjoxNjA1MTAxMDI4fQ.pH72Fh_0N8gSW67Qb5OU4NaiagFrhcbrME52IMy4-5I"))
                dispatch(auth_success(data.token, data.token, data.roleId.toString()));
            }
        })
        .catch((error: any) => {
            dispatch(showError("error", "Lỗi", error.toString()));
            dispatch(changeLoading(false));
        }) 
    }
}

export function LogOut(){
    return async (dispatch: any) => {
        //localStorage.removeItem(REACT_APP_JWT as string);
        //localStorage.removeItem(types.LOCAL_STORAGE_BREADCRUMB);
        //dispatch(auth_ini());
    }
}