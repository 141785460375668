// LOADING
export const LOADING = 'LOADING';

export const AUTH_INI = 'AUTH_INI';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';

//ERROR
export const ERROR_INI = 'ERROR_INI';
export const ERROR_SUCCESS = 'ERROR_SUCCESS';

//AWARD
export const AWARD_INI = 'AWARD_INI';
export const AWARD_SUCCESS = 'AWARD_SUCCESS';
export const LIST_AWARD_SUCCESS = 'LIST_AWARD_SUCCESS';
export const LIST_AWARD_FULL_SUCCESS = 'LIST_AWARD_FULL_SUCCESS';
export const AWARD_FAIL = 'AWARD_FAIL';
export const UPDATE_AWARD_NEW = 'UPDATE_AWARD_NEW';

//CHECK AWARD
export const CHECK_AWARD_INI = 'CHECK_AWARD_INI';
export const CHECK_AWARD_SUCCESS = 'CHECK_AWARD_SUCCESS';
export const CHECK_AWARD_FAIL = 'CHECK_AWARD_FAIL';

//CUSTOMER AWARD
export const CUSTOMER_AWARD_INI = 'CUSTOMER_AWARD_INI';
export const CUSTOMER_AWARD_SUCCESS = 'CUSTOMER_AWARD_SUCCESS';
export const CUSTOMER_AWARD_PAGING = 'CUSTOMER_AWARD_PAGING';
export const CUSTOMER_AWARD_FAIL = 'CUSTOMER_AWARD_FAIL';

//GENERATE
export const GENERATE_INI = 'GENERATE_INI';
export const GENERATE_SUCCESS = 'GENERATE_SUCCESS';
export const GENERATE_FAIL = 'GENERATE_FAIL';


//LOADING
export const CHANGE_LOADING = 'CHANGE_LOADING';

//DETAIL_EVENT
export const DETAIL_EVENT_INI = 'DETAIL_EVENT_INI';
export const DETAIL_EVENT_SUCCESS = 'DETAIL_EVENT_SUCCESS';
export const DETAIL_EVENT_FAIL = 'DETAIL_EVENT_FAIL';

//AREA
export const AREA_INI = 'AREA_INI';
export const AREA_SUCCESS = 'AREA_SUCCESS';
export const LIST_AREA_SUCCESS = 'LIST_AREA_SUCCESS';
export const LIST_AREA_AWARD_SUCCESS = 'LIST_AREA_AWARD_SUCCESS';
export const AREA_FAIL = 'AREA_FAIL';

//EVENT_AWARD
export const EVENT_AWARD_INI = 'EVENT_AWARD_INI';
export const EVENT_LIST_AWARD_SUCCESS = 'EVENT_LIST_AWARD_SUCCESS';
export const EVENT_LIST_AWARD_FULL_SUCCESS = 'EVENT_LIST_AWARD_FULL_SUCCESS';
export const EVENT_AWARD_FAIL = 'EVENT_AWARD_FAIL';

//MANUAL CODE
export const MANUAL_CODE_INI = 'MANUAL_CODE_INI';
export const MANUAL_CODE_SUCCESS = 'MANUAL_CODE_SUCCESS';
export const MANUAL_CODE_PAGING = 'MANUAL_CODE_PAGING';
export const MANUAL_CODE_FAIL = 'MANUAL_CODE_FAIL';