import React from 'react';
import Mobile from './Mobile';

const AdminPage = (props: any) => {

    return (
        <Mobile />
    )
}

export default AdminPage;