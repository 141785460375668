import React, { useState, useEffect, Fragment } from 'react'
// import { useSelector, useDispatch } from 'react-redux';
import {
    LIST_BOX,
    LIST_AWARD1,
    LIST_CUSTOMER,
} from "../../reducer/contains/api";
import * as Status from "../../common/enum";
import * as config from "../../common/config";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import styled from "styled-components";

let axios = require('axios');

let timerCheckAward: any = null;

const Led = (props: any) => {
    const [dimension, setDimension] = useState<any>({ width: 0, height: 0 });
    const [awardId, setAwardId] = useState<any>(0);
    const [listCustomer, setListCustomer] = useState<any>([]);
    const [amount, setAmount] = useState<any>(0);
    const [total, setTotal] = useState<any>(0);
    const [remain, setRemain] = useState<any>(0);
    // const [loading, setLoading] = useState<any>(false);

    useEffect(() => {
        const onResize = () => {
            setDimension({
                width: window.innerWidth,
                height: window.innerHeight
            })
        }

        onResize();

        window.addEventListener('resize', onResize);

        return () => {
            window.removeEventListener('resize', onResize);
        }
    }, [])

    useEffect(() => {
        const GetListGift = (boxId: number) => {
            axios
                .get(`${LIST_AWARD1}?boxId=${boxId}`)
                .then((response: any) => {
                    if (
                        response.status === Status.STATUS_RESPONSE_OK &&
                        response.data.status === Status.STATUS_RESPONSE_OK
                    ) {
                        /* setListAward(response.data.result);*/
                        let listAward = response.data.result;
                        // console.log(listAward);
                        let idx = listAward.findIndex(
                            (x: any) => x.isHome === true && x.boxId === boxId
                        );
                        if (idx > -1) {
                            setAwardId(listAward[idx].awardId);
                            GetListCustomer(listAward[idx].awardId);
                        }
                    }
                })
                .catch((error: any) => {
                    console.log(error);
                });
        };


        const GetListBox = () => {
            axios
                .get(LIST_BOX)
                .then((response: any) => {
                    if (
                        response.status === Status.STATUS_RESPONSE_OK &&
                        response.data.status === Status.STATUS_RESPONSE_OK
                    ) {
                        //setListBox(response.data.result);
                        let listBox = response.data.result;
                        let box = listBox.find((x: any) => x.isHome === true);
                        //console.log();
                        if (box) {
                            console.log(box);
                            // //setBoxId(listBox[idx].boxId);
                            // setShiftId(box.shiftId);
                            GetListGift(box.boxId);
                        }
                    }
                })
                .catch((error: any) => {
                    console.log(error);
                });
        };

        timerCheckAward = setInterval(() => {
            GetListBox();
        }, config.TIME_REFRESH);

        GetListBox();

        return () => {
            clearInterval(timerCheckAward);
        }
        //eslint-disable-next-line
    }, []);

    // useEffect(() => {
    //     if(amount === listCustomer.length){
    //         clearInterval(timerCheckAward);
    //     }
    // }, [listCustomer])

    const GetListCustomer = (awardId: number) => {
        // setLoading(true);
        axios
            .get(`${LIST_CUSTOMER}?awardId=${awardId}`)
            .then((response: any) => {
                if (
                    response.status === Status.STATUS_RESPONSE_OK &&
                    response.data.status === Status.STATUS_RESPONSE_OK
                ) {
                    setListCustomer(response.data.result);
                    // console.log(response.data);
                    const { amount, total, remain } = response.data.detail;
                    // setLoading(false);
                    setAmount(amount);
                    setTotal(total);
                    setRemain(remain);
                }
            })
            .catch((error: any) => {
                console.log(error);
            });
    };

    const renderNo = (row: any, cell: any) => {
        return 1 + cell.rowIndex;
    };

    const TableWrapper = styled.div<any>`
        #tblCustomer .p-datatable-thead > tr > th {
            background-color: rgba(190, 30, 45, 0.7) !important;
            color: #fff !important;
            border: 1px solid #fff !important;
        }
        
        #tblCustomer .p-datatable-tbody > tr > td {
            color: #fff !important;
            border: 1px solid #fff !important;
            background-color: rgba(240, 90, 40, 0.7) !important;    
        }

        #tblCustomer .p-datatable-tbody > .p-datatable-emptymessage > td {
            color: #fff !important;
            border: 1px solid #fff !important;
            background-color: rgba(240, 90, 40, 0.7) !important;
        }

        #tblCustomer .p-datatable .p-datatable-scrollable-header, .p-datatable .p-datatable-scrollable-footer{
            background-color: rgba(190, 30, 45, 0.7) !important;
        }
    `

    const renderBody = () => {
        const { width, height } = dimension;

        let w1 = width;
        let h1 = w1 * 9 / 16;

        let h2 = height;
        let w2 = h2 * 16 / 9;

        // console.log(w1, h1, w2, h2);

        let styleAll = {
            width: `100%`,
            height: `100%`,
            display: `flex`,
            justifyContent: `center`,
            alignItems: `center`,
            backgroundColor: `#000`
        }

        let styleBackground = {
            width: w1 > w2 ? w2 : w1,
            height: h1 > h2 ? h2 : h1,
            backgroundImage: `url(${process.env.PUBLIC_URL}/assets/img/bg.jpg)`,
            backgroundSize: `contain`,
            backgroundRepeat: `no-repeat`,
            backgroundPosition: `center center`
        }

        let styleContent = {
            top: `${(h1 > h2 ? h2 : h1) * 0.43075}px`,
            left: `0px`,
            height: (h1 > h2 ? h2 : h1) * 0.56925,
            // position: `absolute`,
            width: w1 > w2 ? w2 : w1,
        }

        let h3 = (h1 > h2 ? h2 : h1) * 0.56925;
        let w3 = (w1 > w2 ? w2 : w1)

        let styleHeader = {
            width: `100%`,
            height: h3 * 0.08,
            backgroundColor: `#3b2316`,
            display: `flex`,
            justifyContent: `space-between`,
            alignItems: `center`,
            padding: `0px ${w3 * 0.1575}px`,
        }


        let styleItemHeader = {
            width: (w3 - w3 * 0.315) / 3,
            height: `100%`,
            color: `#fff`,
            display: `flex`,
            // justifyContent: `center`,
            alignItems: `center`,
            fontSize: `${h3 * 0.035}px`,
            fontWeight: 700
        }

        let styleTable = {
            width: `100%`,
            height: h3 * 0.92,
            // backgroundColor: `#fff`,
            opacity: 0.9
        }

        let h4 = h3 * 0.92;



        let w5 = w3 * 0.29;

        let titleAmount = {
            width: w5,
            height: (w5) * 380 / 520,
            // backgroundColor: `#fff`,
            backgroundImage: `url(${process.env.PUBLIC_URL}/assets/img/${awardId}.png)`,
            backgroundRepeat: `no-repeat`,
            backgroundSize: `contain`,
            left: w3 * 0.606,
            bottom: -((w5) * 380 / 520) * 0.05,
            // opacity: 0.8
        }

        let w6 = w3 * 0.584;

        let titleGift = {
            width: w6,
            height: (w6) * 240 / 1100,

            // backgroundColor: `#fff`,
            backgroundImage: `url(${process.env.PUBLIC_URL}/assets/img/text-${awardId}.png)`,
            backgroundRepeat: `no-repeat`,
            backgroundSize: `contain`,
            left: w5 * 0.095,
            bottom: ((w5) * 240 / 1100) * 0.26,
            // opacity: 0.8
        }

        let styleHeaderTop = {
            top: 0,
            left: 0,
            height: `${(h1 > h2 ? h2 : h1) * 0.43075}px`,
            width: `100%`,
        }

        return (
            <div style={{ ...styleAll }}>
                <div style={{ ...styleBackground, position: `relative` }}>
                    <div style={{ ...styleHeaderTop }}>
                        <div style={{ width: `100%`, height: `100%`, position: `relative` }}>
                            <div style={{ ...titleGift, position: `absolute` }}></div>
                            <div style={{ ...titleAmount, position: `absolute` }}></div>
                        </div>
                    </div>
                    <div style={{ ...styleContent, position: `absolute` }}>
                        <div style={{ width: `100%`, height: `100%`, position: `relative` }}>
                            <div style={{ ...styleHeader, position: `relative` }}>
                                <div style={{ ...styleItemHeader, justifyContent: `left` }}>
                                    Tổng Số Giải: {amount}
                                </div>
                                <div style={{ ...styleItemHeader, justifyContent: `center` }}>
                                    Tổng Giải Trúng Thưởng: {total}
                                </div>
                                <div style={{ ...styleItemHeader, justifyContent: `right` }}>
                                    Số Giải Còn Lại: {remain}
                                </div>
                            </div>
                            <TableWrapper style={{ ...styleTable }} itemHeight={h4 / 9}>
                                <DataTable
                                    id="tblCustomer"
                                    value={listCustomer}
                                    scrollable={listCustomer.length > 8 ? true : false}
                                    scrollHeight={(listCustomer.length > 8) ? `${(h3 * 0.815)}px` : undefined}
                                    emptyMessage="Chưa có khách hàng trúng giải"
                                >
                                    <Column
                                        field="stt"
                                        header="STT"
                                        style={{ width: `6.8%`, textAlign: "right", height: h4 / 9, fontSize: h4 / 22 }}
                                        headerStyle={{ textAlign: "center", width: `6.8%`, height: h4 / 9, fontSize: h4 / 22 }}
                                        body={renderNo}
                                    ></Column>
                                    <Column
                                        field="code"
                                        header="MÃ SỐ"
                                        style={{ width: `16.125%`, textAlign: "center", height: h4 / 9, fontSize: h4 / 22 }}
                                        headerStyle={{ textAlign: "center", width: `16.125%`, height: h4 / 9, fontSize: h4 / 22 }}
                                    ></Column>
                                    <Column
                                        field="storeName"
                                        header="TÊN KHÁCH HÀNG"
                                        style={{ width: `22.55%`, textAlign: "left", height: h4 / 9, fontSize: h4 / 22 }}
                                        headerStyle={{ textAlign: "center", width: `22.55%`, height: h4 / 9, fontSize: h4 / 22 }}
                                    ></Column>
                                    <Column
                                        field="province"
                                        header="TỈNH THÀNH"
                                        style={{ width: `15.3%`, fontSize: h4 / 22 }}
                                        headerStyle={{ textAlign: "center", width: `15.3%`, height: h4 / 9, fontSize: h4 / 22 }}
                                    ></Column>
                                    <Column
                                        field="address"
                                        header="ĐỊA CHỈ"
                                        style={{ fontSize: h4 / 22 }}
                                        headerStyle={{ textAlign: "center", height: h4 / 9, fontSize: h4 / 22 }}
                                    ></Column>
                                </DataTable>
                            </TableWrapper>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <Fragment>
            {renderBody()}
        </Fragment>
    )
}

export default Led