import { combineReducers } from 'redux';
import auth from './auth';
import error from './error';
import loading from './loading';
import award from './award';
import generate from './generate';
import customeraward from './customeraward';
import checkaward from './checkaward';
import event from './event';
import eventaward from './eventaward';
import area from './area';
import manualcode from './manualcode'

const reducers = combineReducers({
    auth,
    error,
    loading,
    award,
    generate,
    customeraward,
    checkaward,
    event,
    eventaward,
    area,
    manualcode
});

export default reducers;