import React from 'react';
import Error from './Error';

const Layout = (props: any) => {
    const styles = {
        backgroundImage: "url(https://file.tengroup.com.vn/wwwroot/12112020/NtxvW8AzBmIM5sKX6ofaF06HNQnptdJNTfvIl3EGl3cH90I7RreoAs0gsZgeebWlNF5ZbMAGWdcB1ncZ.jpg)",
        zIndex: 1
    }

    return (
        <div className="event-background" style={styles}>
            {props.children}
            <Error />
        </div>
    )
}

export default Layout;