import * as React from "react";
import * as ReactDOM from "react-dom";

// bootstrap
import 'bootstrap/dist/css/bootstrap.css';
// blueprint
//import 'normalize.css/normalize.css';
//import '@blueprintjs/icons/lib/css/blueprint-icons.css';
//import '@blueprintjs/core/lib/css/blueprint.css';
//// select
//import '@blueprintjs/select/lib/css/blueprint-select.css';
//// datetime
//import '@blueprintjs/datetime/lib/css/blueprint-datetime.css';
//// table
//import '@blueprintjs/table/lib/css/table.css';
//// timezone
//import '@blueprintjs/timezone/lib/css/blueprint-timezone.css';
//import 'react-perfect-scrollbar/dist/css/styles.css';
import '../src/assets/css/styles.scss';
//import '../src/assets/css/style.bundle.css';
//// primereact/theme.css
import 'primereact/resources/themes/rhea/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import '../src/assets/css/font-awesome/css/font-awesome.css';


import App from './App';
import * as serviceWorker from './serviceWorker';
import { createStore, compose, applyMiddleware } from 'redux';
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom';
import thunk from 'redux-thunk';
import appReducers from './reducer/reducers/index';

const composeEnhancers = compose;
const store = createStore(appReducers, composeEnhancers(
    applyMiddleware(thunk)
));

ReactDOM.render(
    <Provider store={store}>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </Provider>,
    document.getElementById('root')
);

serviceWorker.unregister();

