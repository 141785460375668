import React from 'react';
import Error from './Error';
import { isMobile } from '../common/function'

const Layout = (props: any) => {

    return (
        <div className="event-background" style={{width: `100%`, height: `100%`}}>
            {props.children}
            <Error />
        </div>
    )
}

export default Layout;