
import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import '../assets/css/login2.css';
import * as actions from '../reducer/actions/index';
//import * as router from '../common/router';

const Login = (props: any) => {
    const {
        ShowError,
        Login
    } = props;

    const owl = useRef<any>(null);

    const [username, setUsername] = useState<string>('');
    const [password, setPassword] = useState<string>('');

    const onPasswordFocus = (e: any) => {
        let owlDiv: any = owl.current;
        if (owlDiv)
            owlDiv.classList.add('password');

        document.querySelectorAll(".hand").forEach(hand => {
            hand.classList.add('password');
        });

        document.querySelectorAll(".arms").forEach(hand => {
            hand.classList.add('password');
        });

        document.querySelectorAll(".arm").forEach(hand => {
            hand.classList.add('password');
        });
        // (document.querySelector('.tongue') as HTMLDivElement).classList.remove('breath');
    }

    const onPasswordBlur = (e: any) => {
        let owlDiv: any = owl.current;
        if (owlDiv)
            owlDiv.classList.remove('password');

        document.querySelectorAll('.hand').forEach(hand => {
            hand.classList.remove('password');
        });

        document.querySelectorAll(".arms").forEach(hand => {
            hand.classList.remove('password');
        });

        document.querySelectorAll(".arm").forEach(hand => {
            hand.classList.remove('password');
        });
    }

    useEffect(() => {
        let passwordInput = document.querySelector('.password');
        (passwordInput as HTMLInputElement).addEventListener('focus', (e: any) => onPasswordFocus(e));
        (passwordInput as HTMLInputElement).addEventListener('blur', (e: any) => onPasswordBlur(e));

        return () => {
            let passwordInput = document.querySelector('.password');
            (passwordInput as HTMLInputElement).removeEventListener('focus', (e) => onPasswordFocus(e));
            (passwordInput as HTMLInputElement).removeEventListener('blur', e => onPasswordBlur(e));
        }
    }, []);


    const onClickLogin = () => {
        ShowError(null, null, null);
        if (username.trim() === "") {
            ShowError("error", "Lỗi", "Không để trống tài khoản !")
            return false;
        }

        if (password.trim() === "") {
            ShowError("error", "Lỗi", "Không để trống mật khẩu !")
            return false;
        }

        let objLogin = {
            Username: username,
            Password: password
        }

        Login(objLogin);
    }

    const KeyPressListener = (e: any) => {
        if (e.key === "Enter") {
            let loginButton = document.querySelector(".login-buton");
            (loginButton as HTMLButtonElement).click();
        }
    }

    useEffect(() => {
        window.addEventListener('keydown', KeyPressListener);
        //window.addEventListener('keyup', KeyPressListener);
        // Remove event listeners on cleanup
        return () => {
            window.removeEventListener('keydown', KeyPressListener);
            //window.removeEventListener('keyup', KeyPressListener);
        };
    }, []);

    return (
        <React.Fragment>
            <div className="form-login">
                <div className="form-content">
                    <div className="owl" ref={owl}>
                        <div className="hand"></div>
                        <div className="hand hand-r"></div>
                        <div className="arms">
                            <div className="arm"></div>
                            <div className="arm arm-r"></div>
                        </div>
                    </div>
                    <div className="form">
                        <div className="form-row">
                            <div className="col-sm-12 text-center">
                                <h3 style={{ color: "#000" }}>SUMITOMO</h3>
                                <span style={{ fontSize: 20, color: "red" }}>LIVE STREAM</span>
                            </div>
                        </div>
                        <div className="form-row" style={{ marginTop: 30 }}>
                            <div className="col-sm-12" style={{ marginBottom: 5 }}>
                                <span style={{ color: "#000" }}>Tài khoản</span>
                            </div>
                            <div className="col-sm-12">
                                <label className="sr-only">Tài khoản</label>
                                <div className="input-group">
                                    <input
                                        type="text"
                                        className="form-control username"
                                        placeholder="Tài khoản"
                                        value={username}
                                        onChange={(e: any) => setUsername(e.target.value)}
                                        //onKeyPress={onKeypress}
                                    />
                                    <div className="input-group-append">
                                        <div className="input-group-text">
                                            <i className="fa fa-user"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="form-row" style={{ marginTop: 15 }}>
                            <div className="col-sm-12" style={{ marginBottom: 5 }}>
                                <span style={{ color: "#000" }}>Mật khẩu</span>
                            </div>
                            <div className="col-sm-12">
                                <label className="sr-only">Mật khẩu</label>
                                <div className="input-group">
                                    <input
                                        type="password"
                                        className="form-control password"
                                        placeholder="********"
                                        value={password}
                                        onChange={(e: any) => setPassword(e.target.value)}
                                        //onKeyPress={onKeypress}
                                    />
                                    <div className="input-group-append">
                                        <div className="input-group-text">
                                            <i className="fa fa-lock"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="form-row" style={{ marginTop: 30 }}>
                            <div className="col-sm-12">
                                <button className="btn btn-dark btn-block login-buton"
                                    onClick={onClickLogin}
                                >
                                    ĐĂNG NHẬP
                            </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

const mapStateToProps = state => {
    return {
        //isLoading: state.auth.isLoading
    }
}

const mapDispatchToProps = dispatch => {
    return {
        Login: (objLogin: any) => dispatch(actions.Login(objLogin)),
        ShowError: (severity: string, summary: string, detail: string) => dispatch(actions.ShowError(severity, summary, detail)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);