import * as actionTypes from '../contains/actionTypes';
const initialState: any = {
    areaused: null,
    listArea: [],
    listAreaAward: [],
    message: ""
}

const reducer = (state = initialState , action:any) => {
    switch (action.type) {
        case actionTypes.AREA_INI:
            return {
                ...state
            };
        case actionTypes.AREA_SUCCESS:
            return {
                ...state,
                areaused: action.areaused,
            }
        case actionTypes.LIST_AREA_SUCCESS:
            return {
                ...state,
                listArea: action.listArea
            }
        case actionTypes.LIST_AREA_AWARD_SUCCESS:
            return {
                ...state,
                listAreaAward: action.listAreaAward
            }
        case actionTypes.AREA_FAIL:
            return {
                ...state,
                message: action.message
            }
        default:
            return state;
    }
}

export default reducer;