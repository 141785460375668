import * as actionTypes from '../contains/actionTypes';
const initialState: any = {
    severity: null,
    summary: null,
    detail: null 
}

const reducer = (state = initialState , action:any) => {
    // console.log(action);
    switch (action.type) {
        case actionTypes.ERROR_INI:
            return {
                severity: null,
                summary: null,
                detail: null 
            };
        case actionTypes.ERROR_SUCCESS:
            return {
                severity: action.severity,
                summary: action.summary,
                detail: action.detail 
            }
        default:
            return state;
    }
}

export default reducer;