import * as actionTypes from '../contains/actionTypes';
const initialState: any = {
    award: null,
    setting: null,
    listAward: [],
    listAwardFull: [],
    backgroundUrl: "",
    message: ""
}

const reducer = (state = initialState , action:any) => {
    switch (action.type) {
        case actionTypes.AWARD_INI:
            return {
                ...state
            };
        case actionTypes.AWARD_SUCCESS:
            return {
                ...state,
                award: action.award,
                setting: action.setting,
                backgroundUrl: action.backgroundUrl
            }
        case actionTypes.LIST_AWARD_SUCCESS:
            return {
                ...state,
                listAward: action.listAward
            }
        case actionTypes.LIST_AWARD_FULL_SUCCESS:
            return {
                ...state,
                listAwardFull: action.listAwardFull
            }
        case actionTypes.AWARD_FAIL:
            return {
                ...state,
                message: action.message
            }
        case actionTypes.UPDATE_AWARD_NEW:
            return {
                ...state,
                isNew: action.isNew
            }
        default:
            return state;
    }
}

export default reducer;