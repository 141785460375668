import * as actionTypes from '../contains/actionTypes';
const initialState: any = {
    listCode: [],
    message: ""
}

const reducer = (state = initialState , action:any) => {
    // console.log(action);
    switch (action.type) {
        case actionTypes.GENERATE_INI:
            return {
                ...state
            };
        case actionTypes.GENERATE_SUCCESS:
            return {
                ...state,
                listCode: action.listCode
            }
        case actionTypes.GENERATE_FAIL:
            return {
                ...state,
                message: action.message
            }
        default:
            return state;
    }
}

export default reducer;