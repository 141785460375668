import * as types from '../contains/actionTypes';
//import {enCryptData,enCryptDataClient,GetProfileString} from '../../authentication/crypto';
import {
    AREA_USED,
    LIST_AREA_EVENT,
    UPDATE_AREA_USED,
    LIST_AREA_AWARD
} from '../contains/api';
import * as Status from '../../common/enum';
import * as config from '../../common/config';

const axios = require("axios");

export const changeLoading = (loading: boolean) => {
    return {
        type: types.CHANGE_LOADING,
        loading
    };
}

export const AreaInit = () => {
    return {
        type: types.AREA_INI
    };
};

export const AreaSuccess = (areaused: any) => {
    return {
        type: types.AREA_SUCCESS,
        areaused
    }
}

export const ListAreaSuccess = (listArea: any) => {
    return {
        type: types.LIST_AREA_SUCCESS,
        listArea
    }
}

export const ListAreaAwardSuccess = (listAreaAward: any) => {
    return {
        type: types.LIST_AREA_AWARD_SUCCESS,
        listAreaAward
    }
}

export const showError = (severity: any, summary: any, detail: any) => {
    return {
        type: types.ERROR_SUCCESS,
        severity,
        summary,
        detail
    }
}



export function GetAreaUsed(eventId: number) {
    return async (dispatch: any) => {
        dispatch(showError(null, null, null))
        //dispatch(GenerateSuccess([]));
        axios.get(AREA_USED, {
            params: {
                eventId: eventId
            }
        })
            .then((response: any) => {
                if (response.status === Status.STATUS_RESPONSE_OK) {
                    const data: any = response.data;
                    const {
                        areaused,
                    } = data;
                    dispatch(AreaSuccess(areaused));
                    dispatch(changeLoading(false));
                }
            })
            .catch((error: any) => {
                alert(error.toString());
                dispatch(changeLoading(false));
            })
    }
}

export function ListAreaByEventId(eventId: number) {
    return async (dispatch: any) => {
        dispatch(changeLoading(true));
        dispatch(showError(null, null, null))
        axios.get(LIST_AREA_EVENT, {
                params: {
                    eventId: eventId
                }
            })
            .then((response: any) => {
                if (response.status === Status.STATUS_RESPONSE_OK) {
                    const data: any = response.data;
                    const {
                        listAreaEvent
                    } = data;

                    //console.log(data);

                    dispatch(ListAreaSuccess(listAreaEvent))
                    dispatch(changeLoading(false));
                }
            })
            .catch((error: any) => {
                dispatch(showError("error", "Lỗi", error.toString()));
                dispatch(changeLoading(false));
            })
    }
}

export function ListAreaAward(eventId: number, areaId: number) {
    return async (dispatch: any) => {
        dispatch(changeLoading(true));
        dispatch(showError(null, null, null))
        axios.get(LIST_AREA_AWARD, {
            params: {
                eventId: eventId, 
                areaId: areaId
            }
        })
            .then((response: any) => {
                if (response.status === Status.STATUS_RESPONSE_OK) {
                    const data: any = response.data;
                    const {
                        listAward
                    } = data;

                    //console.log(data);

                    dispatch(ListAreaAwardSuccess(listAward))
                    dispatch(changeLoading(false));
                }
            })
            .catch((error: any) => {
                dispatch(showError("error", "Lỗi", error.toString()));
                dispatch(changeLoading(false));
            })
    }
}

export function UpdateAreaIsUsed(objUpdate: any) {
    return async (dispatch: any) => {
        dispatch(changeLoading(true));
        dispatch(showError(null, null, null))
        axios.post(UPDATE_AREA_USED, objUpdate)
            .then((response: any) => {
                if (response.status === Status.STATUS_RESPONSE_OK) {
                    const data: any = response.data;
                    dispatch(changeLoading(false));
                }
            })
            .catch((error: any) => {
                dispatch(showError("error", "Lỗi", error.toString()));
                dispatch(changeLoading(false));
            })
    }
}
