import React, { useEffect, useState, Fragment, useCallback } from "react";
import { connect } from "react-redux";
import * as actions from "../../reducer/actions/index";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
//import { InputNumber } from 'primereact/inputnumber';
import { InputText } from "primereact/inputtext";
//import ColorPicker from 'rc-color-picker';

import {
  LIST_BOX,
  LIST_AWARD1,
  //UPDATE_SHOW_BOX,
  //UPDATE_SHOW_AWARD,
  LIST_CUSTOMER,
  ADD_CODE,
  DEL_CODE,
  ADD_MANUAL_CODE,
  //ADD_CODE,
  SET_LIST_NUMBER,
  CLR_LIST_NUMBER,
} from "../../reducer/contains/api";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { confirmDialog } from "primereact/confirmdialog";
import * as config from "../../common/config";
import "rc-color-picker/assets/index.css";
import * as Status from "../../common/enum";

const _ = require("lodash");
const axios = require("axios");
import { debounce } from "lodash";

const MobileView = (props: any) => {
  const {
    //listArea,
    //listAreaAward,
    //GetListAreaAward,
    ShowError,
  } = props;

  const refCode: any = React.useRef(null);

  const [listBox, setListBox] = useState<any>([]);
  const [boxId, setBoxId] = useState(0);
  const [listAward, setListAward] = useState<any>([]);
  const [awardId, setAwardId] = useState(0);
  const [code, setCode] = useState("");
  const [disableButton1, setDisabledButton1] = useState(true);
  const [disableButton2, setDisabledButton2] = useState(true);
  const [listCustomer, setListCustomer] = useState([]);
  const [prefix, setPrefix] = useState("");
  const [number1, setNumber1] = useState("");
  const [number2, setNumber2] = useState("");
  const [number3, setNumber3] = useState("");
  const [number4, setNumber4] = useState("");
  const [awardName, setAwardName] = useState("");
  const [objUser, setObjUser] = useState<any>(null);

  const GetListCustomer = (awardId: number) => {
    //setLoading(true);
    axios
      .get(`${LIST_CUSTOMER}?awardId=${awardId}`)
      .then((response: any) => {
        if (
          response.status === Status.STATUS_RESPONSE_OK &&
          response.data.status === Status.STATUS_RESPONSE_OK
        ) {
          setListCustomer(response.data.result);
          console.log(response.data.result)
          let list: any = [...response.data.result];

          if (code && code !== "") {
            let objUser: any = list.find(x => x.code === `AA-${code}`);
            if (objUser) {
              setObjUser(objUser);
            }
          } else {
            setObjUser(list[0]);
          }
        }
      })
      .catch((error: any) => {
        console.log(error);
      });
  };

  const GetListBox = () => {
    axios
      .get(LIST_BOX)
      .then((response: any) => {
        if (
          response.status === Status.STATUS_RESPONSE_OK &&
          response.data.status === Status.STATUS_RESPONSE_OK
        ) {
          setListBox(response.data.result);
          let listBox = response.data.result;
          let idx = listBox.findIndex((x) => x.isHome === true);
          console.log(listBox[idx]);
          if (idx > -1) {
            setBoxId(listBox[idx].boxId);
            GetListGift(listBox[idx].boxId);
            if (listBox[idx].boxId === 1) {
              setPrefix("AA-");
            } else {
              setPrefix("BB-");
            }
          }
        }
      })
      .catch((error: any) => {
        console.log(error);
      });
  };

  const GetListGift = (boxId) => {
    axios
      .get(`${LIST_AWARD1}?boxId=${boxId}`)
      .then((response: any) => {
        if (
          response.status === Status.STATUS_RESPONSE_OK &&
          response.data.status === Status.STATUS_RESPONSE_OK
        ) {
          setListAward(response.data.result);
          let listAward = response.data.result;
          let idx = listAward.findIndex(
            (x: any) => x.isHome === true && x.boxId === boxId
          );
          console.log(listAward);
          if (idx > -1) {
            setAwardId(listAward[idx].awardId);
            setAwardName(`${listAward[idx].name}: ${listAward[idx].awardName}`);
            GetListCustomer(listAward[idx].awardId);
          } else {
            setAwardId(listAward[0].awardId);
            setAwardName(`${listAward[0].name}: ${listAward[0].awardName}`);
            GetListCustomer(listAward[0].awardId);
          }
        }
      })
      .catch((error: any) => {
        console.log(error);
      });
  };

  useEffect(() => {
    GetListBox();
  }, []);

  const onChangeListBox = (e) => {
    setBoxId(e.value);
    let idx = listBox.findIndex((x) => x.boxId === e.value);
    if (listBox[idx].boxName.toString().includes("AA")) {
      setPrefix("AA-");
    } else {
      setPrefix("BB-");
    }

    GetListGift(e.value);
    setAwardId(0);
    setDisabledButton1(false);
  };

  const onChangeAward = (e) => {
    setAwardId(e.value);
    GetListCustomer(e.value);
    let list: any = [...listAward];

    let obj = list.find((x: any) => x.awardId === e.value);
    if (obj)
      setAwardName(`${obj.name}: ${obj.awardName}`);


    //setDisabledButton2(false);
  };

  const templateBox = (option) => {
    //console.log(option);
    return <div>{`${option.boxName} (${option.shiftName}) `}</div>;
  };

  const templateBoxValue = (option, props) => {
    if (option) {
      return <div>{`${option.boxName} (${option.shiftName}) `}</div>;
    }
    return <span>{props.placeholder}</span>;
  };

  const logOut = (e) => {
    localStorage.clear();
    window.location.href = "/";
  };

  const updateCode = () => {
    //e.preventDefault();

    if (code.trim() === "" || code.trim().length < 2) {
      alert("Vui lòng nhập số đủ 2 chữ số!");
      return;
    }

    const objUpdate = {
      awardId,
      code,
      boxId,
    };

    console.log(objUpdate);

    fetch(ADD_MANUAL_CODE, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(objUpdate),
    })
      .then((response) => response.json())
      .then((data) => {
        // console.log(data);
        if (data.status === Status.STATUS_RESPONSE_OK) {
          GetListCustomer(awardId);
          // setCode("");
        } else {
          alert(data.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const keyPress = (e: any, index: number) => {
    if (e.key === "Enter") {
      var r = confirm("Bạn có muốn nhập số này không ?");
      const value = e.target.value;

      if (r) {
        const objUpdate = {
          number: value === "" ? null : parseInt(value),
          type: index,
        };

        //console.log(objUpdate)

        fetch(SET_LIST_NUMBER, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify(objUpdate),
        })
          .then((response) => response.json())
          .then((data) => {
            if (data.status === Status.STATUS_RESPONSE_OK) {
              console.log(data);
            } else {
              alert(data.message);
            }
          })
          .catch((error) => {
            console.error("Error:", error);
          });
      }
    }
  };

  const resetCode = () => {
    setDisabledButton1(true);
    axios
      .get(CLR_LIST_NUMBER)
      .then((response: any) => {
        if (
          response.status === Status.STATUS_RESPONSE_OK &&
          response.data.status === Status.STATUS_RESPONSE_OK
        ) {
          setNumber1("");
          setNumber2("");
          setNumber3("");
          setNumber4("");
          // setCode("");
        }
      })
      .catch((error: any) => {
        console.log(error);
      });
  };

  //const debounceChange = useCallback(debounce(updateSpecialCode, 1000), [])

  const onKeyInputCode = (e: any) => {
    if (e.key === "Enter") {
      var r = confirm("Bạn có muốn nhập số này không ?");
      if (r) {
        updateCode();
      }
    }
  };

  const templateDel = (row, cell) => {
    return (
      <Button
        label="XÓA"
        className="p-button-sm"
        icon="pi pi-trash"
        onClick={(e) => delRow(e, row)}
      />
    );
  };

  const delRow = (e: any, row: any) => {
    const codeDel = row.code;

    const objDel = {
      boxId,
      code: codeDel,
      awardId,
    };

    fetch(DEL_CODE, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },

      body: JSON.stringify(objDel),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === Status.STATUS_RESPONSE_OK) {
          GetListCustomer(awardId);
          setCode("");
        } else {
          alert(data.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const renderInputCode = () => {
    switch (awardId) {
      case 1:
        return (
          <Fragment>
            <div className="p-grid">
              <div className="p-col-9">
                <div className="p-grid">
                  <div className="p-col">
                    <div className="p-grid">
                      <div className="p-col-4">
                        <InputText
                          placeholder="0"
                          style={{ width: "100%", textAlign: "right" }}
                          value={number1}
                          onChange={(e) => {
                            setNumber1(e.target.value);
                          }}
                          maxLength={1}
                          onKeyPress={(e) => keyPress(e, 1)}
                          tabIndex={8}
                        />
                      </div>
                      <div className="p-col-4">
                        <InputText
                          placeholder="0"
                          style={{ width: "100%", textAlign: "right" }}
                          value={number2}
                          onChange={(e) => {
                            setNumber2(e.target.value);
                          }}
                          maxLength={1}
                          onKeyPress={(e) => keyPress(e, 2)}
                          tabIndex={9}
                        />
                      </div>
                      <div className="p-col-4">
                        <InputText
                          placeholder="0"
                          style={{ width: "100%", textAlign: "right" }}
                          value={number3}
                          onChange={(e) => {
                            setNumber3(e.target.value);
                          }}
                          maxLength={1}
                          onKeyPress={(e) => keyPress(e, 3)}
                          tabIndex={10}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="p-col-3">
                <Button
                  label="CODE MỚI"
                  className="p-button-success btn-block"
                  onClick={resetCode}
                />
              </div>
            </div>
            <div className="p-grid" style={{ paddingTop: 0 }}>
              <div className="p-col-9 p-nogutter">
                <div className="p-inputgroup">
                  <span className="p-inputgroup-addon">{prefix}</span>
                  <InputText
                    placeholder="Nhập Code"
                    style={{ width: "100%" }}
                    value={code}
                    onChange={(e) => setCode(e.target.value)}
                    //onFocus={refCode.current?.select()}
                    onKeyPress={onKeyInputCode}
                    ref={refCode}
                    maxLength={3}
                  />
                </div>
              </div>
              <div className="p-col-3">
                <Button
                  label="NHẬP CODE"
                  className="p-button-success btn-block"
                  onClick={updateCode}
                />
              </div>
            </div>
          </Fragment>
        );
      default:
        return (
          <div className="p-grid">
            <div className="p-col-9">
              <div className="p-inputgroup">
                <span className="p-inputgroup-addon">{prefix}</span>
                <InputText
                  placeholder="Nhập Code"
                  style={{ width: "100%" }}
                  value={code}
                  onChange={(e) => setCode(e.target.value)}
                  //onFocus={refCode.current?.select()}
                  onKeyPress={onKeyInputCode}
                  ref={refCode}
                  maxLength={3}
                />
              </div>{" "}
            </div>
            <div className="p-col-3">
              <Button
                label="NHẬP CODE"
                className="p-button-success btn-block"
                onClick={updateCode}
                id="btnInput"
              />
            </div>
          </div>
        );
    }
  };

  return (
    <React.Fragment>
      {/* <div className="container-fluid" style={{ height: `70vh` }}>
        {awardName !== "" && (
          <div className="row" style={{ paddingTop: 30 }}>
            <div className="col-md-12 text-center">
              <h1 style={{ fontSize: `10vh`, color: `#b13042` }}>
                <b>{awardName}</b>
              </h1>
            </div>
          </div>
        )}
        {objUser && Object.keys(objUser).length > 0 && (
          <React.Fragment>
            <div className="row">
              <div className="col-md-12 text-center">
                <b style={{ fontSize: `15vh`, color: `#0061FE` }}>{objUser.code}</b>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 text-center">
                <b style={{ fontSize: `9vh`, color: `#b13042` }}>{objUser.storeName}</b>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 text-center">
                <b style={{ fontSize: `7vh`, color: `#0061FE` }}>{`Điạ chỉ: ${objUser.address}`}</b>
              </div>
            </div>
          </React.Fragment>
        )}
      </div> */}
      <div className="container" style={{ maxWidth: 900, marginTop: 30 }}>
        {boxId === 1 ? (
          <div className="p-grid text-center">
            <div className="p-col-12 text-center">
              <h4>ĐẠI LÝ CẤP 1</h4>
            </div>
          </div>
        ) : (
          <div className="p-grid text-center">
            <div className="p-col-12 text-center">
              <h4>ĐẠI LÝ CẤP 2</h4>
            </div>
          </div>
        )}
        {/* <div className="p-grid">
                    <div className="p-col-12">
                        <span style={{ margin: 0, color: "#000", fontWeight: 'normal' }}>BẢNG ĐANG ĐƯỢC CHỌN</span>
                    </div>
                </div>
                <div className="p-grid">
                    <div className="p-col-9">
                        <Dropdown
                            optionValue="boxId"
                            optionLabel="boxName"
                            value={boxId}
                            options={listBox}
                            onChange={onChangeListBox}
                            placeholder="Chọn bảng..."
                            itemTemplate={templateBox}
                            valueTemplate={templateBoxValue}
                            style={{ width: "100%" }}
                        />
                    </div>
                </div> */}
        <div className="p-grid">
          <div className="p-col-4">
            <span style={{ margin: 0, color: "#000", fontWeight: "normal", fontSize: 14 }}>
              GIẢI ĐANG ĐƯỢC CHỌN
            </span>
          </div>
          {/* {awardId === 1 && (
            <div className="p-col-8">
              <div className="p-grid p-nogutter">
                <div className="p-col-3 p-nogutter">
                  <span style={{ color: "#000", fontSize: 14 }}>
                    Hàng trăm (LED)
                  </span>
                </div>
                <div className="p-col-3 p-nogutter">
                  <span style={{ color: "#000", fontSize: 14 }}>
                    Hàng chục (LED)
                  </span>

                </div>
                <div className="p-col-3 p-nogutter">
                  <span style={{ color: "#000", fontSize: 14 }}>
                    Hàng đơn vị (LED)
                  </span>
                </div>
              </div>
            </div>
          )} */}
        </div>
        <div className="p-grid">
          <div className="p-col-4">
            <Dropdown
              optionValue="awardId"
              optionLabel="name"
              value={awardId}
              options={listAward}
              onChange={onChangeAward}
              placeholder="Chọn giải thưởng..."
              style={{ width: "100%" }}
            />
          </div>
          <div className="p-col-8">
            {/* {renderInputCode()} */}
            <div className="p-grid">
              <div className="p-col-9">
                <div className="p-inputgroup">
                  <span className="p-inputgroup-addon">{prefix}</span>
                  <InputText
                    placeholder="Nhập Code"
                    style={{ width: "100%" }}
                    value={code}
                    onChange={(e) => setCode(e.target.value)}
                    //onFocus={refCode.current?.select()}
                    onKeyPress={onKeyInputCode}
                    ref={refCode}
                    maxLength={boxId === 1 ? 3 : 4}
                  />
                </div>{" "}
              </div>
              <div className="p-col-3">
                <Button
                  label="NHẬP CODE"
                  className="p-button-success btn-block"
                  onClick={updateCode}
                  id="btnInput"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="p-grid">
          <div className="p-col-12">
            <DataTable
              value={listCustomer}
              //className="table-customer-reward"
              rows={100}
              paginator
              scrollable
              scrollHeight="200px"
              emptyMessage="Chưa có khách hàng trúng thưởng"
              rowsPerPageOptions={[100, 200, 500]}
            >
              <Column field="code" header="MÃ SỐ" />
              <Column field="storeName" header="TÊN KHÁCH HÀNG"></Column>
              <Column
                field="action"
                style={{ width: 100 }}
                body={templateDel}
              />
            </DataTable>
          </div>
        </div>
        <div className="p-grid" style={{ marginTop: 40 }}>
          <div className="p-col-12 text-center">
            <Button
              label="ĐĂNG XUẤT"
              className="p-button-danger"
              onClick={logOut}
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state: any) => {
  //console.log(state)
  return {
    listArea: state.area.listArea,
    listAreaAward: state.area.listAreaAward,
    loading: state.loading.status,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    ShowError: (severity: string, summary: string, detail: string) =>
      dispatch(actions.ShowError(severity, summary, detail)),
    GetListAreaAward: (eventId: number, areaId: number) =>
      dispatch(actions.ListAreaAward(eventId, areaId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MobileView as any);
