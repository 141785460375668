import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { Toast } from 'primereact/toast';

const Error = (props: any) => {
    const {
        error
    } = props;

    const toast: any = useRef<any>(null);

    useEffect(() => {
        if (error.severity !== null && error.summary !== null && error.detail !== null)
        {
            const {
                severity,
                summary,
                detail
            } = error;

            toast.current.show({severity: severity, summary: summary, detail: detail});
        }

        //console.log(error);
    }, [error]);
  
    return <Toast ref={toast} style={{ paddingTop: 70 }} />
}

const mapStateToProps = state => {  
    return {
        error: state.error
    }
}

export default connect(mapStateToProps, null)(Error);
